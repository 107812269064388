import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Loader } from "src/ui";
import { PrimaryButton } from "@fluentui/react";
import ThemeContext from "src/style/ThemeContext";
import api from "src/spintr/SpintrApi";
import PopupHeader from "../../PopupHeader";

interface IProps {
    editor: any;
    onClose: any;
}

interface IState {
    isLoading: any;
    courses?: any;
    selectedTag?: any;
}

class TinyAcademyFunction extends Component<IProps, IState> {
    public static contextType = ThemeContext;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                api.get("/api/v1/academy/admin/courses", {
                    params: {
                        orderByColumn: "Name",
                        skip: 0,
                        take: 9999,
                        isAscending: true,
                        guidesAndCourses: true,
                    },
                }).then((response) => {
                    const courses = response.data.items
                        .filter((c) => !c.isDraft && c.isPublished)
                        .map((c) => ({
                            key: c.id,
                            id: c.id,
                            text: c.name,
                            isGuide: c.isGuide,
                        }));

                    this.setState({
                        courses,
                        isLoading: false,
                    });
                });
            }
        );
    }

    courseSelected = () => {
        const id = this.state.selectedTag.id;
        const name = this.state.selectedTag.text;
        const html = '<div><span class="redactorAcademy academy-' + id + (this.state.selectedTag.isGuide ? ' isGuide' : '') + '">' + name + "</span></div> <br />";

        this.props.editor.insertContent(html);

        this.props.onClose();
    }

    public render(): ReactNode {
        return (
            <Dialog
                hidden={false}
                containerClassName="dialogWithPopupHeader"
                onDismiss={this.props.onClose}
                modalProps={{
                    isBlocking: true,
                    allowTouchBodyScroll: true,
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: localize("appAcademy"),
                    showCloseButton: true,
                    closeButtonAriaLabel: localize("Stang"),
                }}
            >
                <PopupHeader text={localize("appAcademy")} onClose={this.props.onClose} />
                {this.state.isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <Dropdown
                            selectedKey={!!this.state.selectedTag ? this.state.selectedTag.key : null}
                            onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                                this.setState({
                                    selectedTag: item,
                                });
                            }}
                            options={this.state.courses}
                            placeholder={localize("SELECT_COURSE_OR_GUIDE")}
                        />
                        <DialogFooter>
                            <PrimaryButton
                                theme={this.context}
                                onClick={this.courseSelected}
                                text={localize("Valj")}
                            />
                        </DialogFooter>
                    </div>
                )}
            </Dialog>
        );
    }
}

export default TinyAcademyFunction;
