import React from "react";
import { Label, UnstyledButton } from "src/ui";
import "./AcademyCourseSidebarLesson.scss";
import { IAcademyCourse, IAcademyLesson } from "src/academy/types";
import AcademyCourseSidebarProgressCircleSmall from "../AcademyCourseSidebarProgressCircleSmall/AcademyCourseSidebarProgressCircleSmall";
import RunningLabel from "../RunningLabel/RunningLabel";

interface IProps {
    lesson: IAcademyLesson;
    activeStepId?: number;
    isGuide?: boolean;
    goToLesson: (lesson: IAcademyLesson) => void;
}

const AcademyCourseSidebarLesson = (props: IProps) => {
    const isActive = props.activeStepId === props.lesson.id;

    return (
        <UnstyledButton
            className="AcademyCourseSidebarLesson"
            disabled={!props.lesson.userProgress.isEligable}
            onClick={() => props.goToLesson(props.lesson)}>
            <AcademyCourseSidebarProgressCircleSmall
                isDone={props.lesson.userProgress.isCompleted}
                isEligable={props.lesson.userProgress.isEligable}
                isActive={isActive}
            />
            <Label
                size="body-2"
                lineThrough={!isActive && props.lesson.userProgress.isCompleted && !props.isGuide}
                weight={isActive || props.lesson.userProgress.isCompleted ? "medium" : undefined}
                color={isActive ? "dark-grey" : "grey"}>
                {props.lesson.name}
            </Label>
            {isActive && <RunningLabel langTag={"Aktiv"}  />}
        </UnstyledButton>
    )
}

export default AcademyCourseSidebarLesson;
