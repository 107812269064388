import React, { useMemo, useState } from "react";
import { RecommendedAudience, RecommendedAudiencesProps } from "./RecommendedAudiences.types";
import { useSelector } from "react-redux";
import { localize } from "src/l10n";
import { CaptionBolder } from "../Text";
import classNames from "classnames";
import { DefaultButton } from "@fluentui/react";

const RecommendedAudiences: React.FC<RecommendedAudiencesProps> = ({ onAudienceSelected, targetIds }) => {
    const activeUser = useSelector<Spintr.AppState, Spintr.IActiveUser>(
        (appState) => appState.profile.active,
    );

    const targetGroups = useMemo<RecommendedAudience[]>(
        () => {
            const groups: RecommendedAudience[] = [];

            groups.push({ 
                id: 0, name: localize("Alla"),
                key: 0,
            });
            
            if (activeUser.department){
                groups.push({
                    id: activeUser.department.id as number,
                    name: activeUser.department.name as string,
                    info: localize("Avdelning"),
                    subText: localize("Avdelning"),
                    key: activeUser.department.id as number,
                    type: 2,
                });
            }

                if (activeUser.department.office){
                    groups.push({
                        id: activeUser.department.office.id as number,
                        name: activeUser.department.office.name as string,
                        info: localize("Kontor"),
                        subText: localize("Kontor"),
                        key: activeUser.department.office.id as number,
                        type: 8,
                    });
                }

                if (activeUser.department.office.company) {
                    groups.push({
                        id: activeUser.department.office.company.id as number,
                        name: activeUser.department.office.company.name as string,
                        info: localize("Foretag"),
                        subText: localize("Foretag"),
                        key: activeUser.department.office.company.id as number,
                        type: 65,
                    });
                }
                return groups;
        }, 
        [activeUser]
    )

    return (
        <div className="RecommendedAudiences">
            <CaptionBolder color="contentDark">
                {localize("YourTargetAudience")}
            </CaptionBolder>
            <div className="TargetGroupList">
                <CaptionBolder color="grayDark">
                    {localize("YourTargetGroups")}
                </CaptionBolder>
                <div className="TargetGroupList-items">
                    {targetGroups.map((audience) => (
                        <DefaultButton
                            key={audience.id}
                            onClick={() => onAudienceSelected(audience)}
                            className={classNames("TargetGroupItem", {
                                "selected": targetIds.includes(audience.id) ||
                                    (targetIds.length === 0 && audience.id < 1),
                            })}
                        >
                            {audience.name}
                        </DefaultButton>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RecommendedAudiences;