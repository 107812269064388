import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localize } from "src/l10n";
import { VisageSidebarMode, setSidebarMode } from "src/sidebar";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Label, PageHeader, UnstyledButton } from "src/ui";
import "./SystemStatusStartView.scss";
import { SpintrTypes } from "src/typings";
import { Dropdown, Modal } from "@fluentui/react";
import CalypsoContentWithSidebar from "src/ui/components/CalypsoContentWithSidebar";
import SpintrLoader from "src/ui/components/Loader";
import SystemStatusResource from "./SystemStatusResource";
import SystemStatus from "./SystemStatus";
import { FormControl, FormSection } from "src/ui/components/Forms";
import PopupHeader from "src/ui/components/PopupHeader";
import SystemStatusForm from "./SystemStatusForm";
import Axios, { CancelToken } from "axios";
import { debounce } from "src/utils";
import { IContentHeaderButton } from "src/ui/components/PageHeader";
import classNames from "classnames";
import { Conditional } from "src/components/Conditional";
import { ConditionalRender } from "src/components/ConditionalRender";
import { useHistory } from "react-router";
import AppDisabledBanner from "src/ui/components/AppDisabledBanner/AppDisabledBanner";
import { setSystemStatusUnreadCount } from "./actions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

type StateProps = {
    currentUser:        Spintr.IActiveUser;
    viewMode:           SpintrTypes.ViewMode;
    isSmallViewMode:    boolean;
};

type ActiveSystemStatus = {
    endDate: string | Date;
    id: number;
    prioritized: boolean;
    startDate: string | Date;
    status: number;
    text: string;
}

type SystemStatusResource
    = Spintr.ISystemStatusResource
    & { activeSystemStatuses: ActiveSystemStatus[] };

interface ISystemStatusResourceCategory {
    id: number;
    key: string;
    name: string;
    isActive?: boolean;
    resources: SystemStatusResource[];
}

function SystemStatusStartView() {
    const contentRef = useRef<any>();
    const dispatch = useDispatch();

    const {
        currentUser,
    } = useSelector<Spintr.AppState, StateProps>((appState) => ({
        currentUser: appState.profile.active,
        viewMode: appState.ui.viewMode,
        isSmallViewMode: appState.ui.isSmallViewMode,
    }));

    /* Different state things */
    const [searchText, setSearchText] = useState<string>();
    const [data, setData] = useState<SystemStatusResource[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [categoryId, setCategoryId] = useState<number>(0);
    const [status, setStatus] = useState<number>(-1);
    const [showNewResourceModal, setShowNewResourceModal] = useState(false);
    const [newResource, setNewResource] = useState<Spintr.ISystemStatusResource>();
    const [expandedCategories, setExpandedCategories] = useState<string[]>(["active", "planned"]);
    const [helpContent, setHelpContent] = useState<Spintr.HelpContent | undefined>(undefined);

    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();

        api.get<Spintr.HelpContent>("/api/v1/help/10", { cancelToken: cancelTokenSource.token })
            .then((response) => setHelpContent(response.data))
            .catch(() => {
                // Ignore
            });

        return () => cancelTokenSource.cancel();
    }, [setHelpContent]);

    const debouncedSetSearchText = useMemo(() => debounce(setSearchText, 250), [setSearchText]);

    const fetch = useCallback(async (cancelToken?: CancelToken) => {
        setIsLoading(true);

        const params = {
            searchText,
            categoryId: categoryId > 0 ? categoryId : undefined,
            status: status > -1 ? status : undefined,
            includeSuggestions: true
        };

        try {
            const response = await api.get<SystemStatusResource[]>(
                "/api/v1/systemstatuses/resources",
                { params, cancelToken }
            );

            setData(response.data);
            setIsLoading(false);
        } catch (err) {
            return;
        }
    }, [searchText, categoryId, status]);

    const toggleShowNewResourceModal = useCallback((id?: number) => {
        // @ts-ignore
        setNewResource({
            id,
            targets: [],
            owners: [
                {
                    id: currentUser.id,
                    key: currentUser.id,
                    name: currentUser.name,
                    imageUrl: currentUser.images.feedComposer,
                    subText: currentUser.department.name,
                },
            ],
            faq: [],
            categoryId: -1
        });
        setShowNewResourceModal(!showNewResourceModal);
    }, [showNewResourceModal, currentUser]);

    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();

        fetch(cancelTokenSource.token);

        return () => cancelTokenSource.cancel();
    }, [fetch]);

    useEffect(() => {
        dispatch(setSidebarMode(VisageSidebarMode.noMenu));
        dispatch(setSystemStatusUnreadCount(0));
        api.put("/api/v1/operatinginfo/updatelastseen");


        return () => dispatch(setSidebarMode(VisageSidebarMode.menu));
    }, []);

    const categories : ISystemStatusResourceCategory[] = useMemo(() => {
        const activeResources = data.filter(
            (x) => x.status == SpintrTypes.SystemStatusType.Ongoing,
        );

        const plannedResources = data.filter(
            (x) => x.status == SpintrTypes.SystemStatusType.Planned,
        );

        let categories: ISystemStatusResourceCategory[] = [];
        let resourcesWithoutCategory: SystemStatusResource[] = [];
        let resourceSuggestions: SystemStatusResource[] = [];

        for (let resource of data) {
            if (activeResources.find(x => x.id === resource.id) ||
                plannedResources.find(x => x.id === resource.id )) {
                continue;
            }

            if (resource.isSuggestion) {
                resourceSuggestions.push(resource);
                continue;
            }

            if (!resource.categoryId) {
                resourcesWithoutCategory.push(resource);
                continue;
            }

            const foundCategory = categories.find(x => x.id === resource.categoryId);

            if (foundCategory) {
                foundCategory.resources.push(resource);
            } else {
                categories.push({
                    id: resource.categoryId,
                    key: resource.categoryId.toString(),
                    name: resource.categoryName,
                    isActive: false,
                    resources: [resource]
                });
            }
        }

        categories = categories.sort((a, b) => (a.name > b.name ? 1 : -1));

        if (resourceSuggestions.length > 0) {
            categories.push({
                id: -1,
                key: "suggestions",
                name: localize("SUGGESTIONS"),
                isActive: false,
                resources: resourceSuggestions
            });
        }

        if (resourcesWithoutCategory.length > 0) {
            categories.push({
                id: -2,
                key: "all",
                name: localize("Alla"),
                isActive: false,
                resources: resourcesWithoutCategory
            });
        }

        return [
            ...(activeResources.length > 0 ?
                [{
                    id: -3,
                    key: "active",
                    name: localize("Aktiva"),
                    isActive: true,
                    resources: activeResources
                }] :
                []
            ),
            ...(plannedResources.length > 0 ?
                [{
                    id: -1,
                    key: "planned",
                    name: localize("Planerade"),
                    isActive: true,
                    resources: plannedResources
                }] :
                []
            ),
            ...categories
        ];
    }, [data]);

    const headerButtons = useMemo<IContentHeaderButton[]>(() => {
        const buttons: IContentHeaderButton[] = [{
            key: "Filter",
            text: localize("Filter"),
            icon: "sort",
            subMenuProps: {
                items: [
                    {
                        key: "status",
                        text: localize("Status"),
                        onClick: () => {},
                        subMenuProps: {
                            items: [{
                                key: -1,
                                text: localize("Alla"),
                                onClick: () => {
                                    setStatus(-1);
                                }
                            }, {
                                key: SpintrTypes.SystemStatusType.AllClear,
                                text: localize("ALL_CLEAR"),
                                onClick: () => {
                                    setStatus(SpintrTypes.SystemStatusType.AllClear);
                                }
                            }, {
                                key: SpintrTypes.SystemStatusType.Ongoing,
                                text: localize("Pagaende"),
                                onClick: () => {
                                    setStatus(SpintrTypes.SystemStatusType.Ongoing);
                                }
                            }, {
                                key: SpintrTypes.SystemStatusType.Done,
                                text: localize("Avklarad"),
                                onClick: () => {
                                    setStatus(SpintrTypes.SystemStatusType.Done);
                                }
                            }, {
                                key: SpintrTypes.SystemStatusType.Planned,
                                text: localize("Planerad"),
                                onClick: () => {
                                    setStatus(SpintrTypes.SystemStatusType.Planned);
                                }
                            }]
                        }
                    },
                    {
                        key: "category",
                        text: localize("Kategori"),
                        onClick: () => {},
                        subMenuProps: {
                            items: [
                                {
                                    key: 0,
                                    text: localize("Alla"),
                                    onClick: () => {
                                        setCategoryId(0);
                                    }
                                },
                                ...categories.filter(x => x.id > 0).map((c) => {
                                    return {
                                        key: c.id,
                                        text: c.name,
                                        onClick: () => {
                                            setCategoryId(c.id);
                                        }
                                    }
                                })
                            ]
                        }
                    }
                ]
            }
        }];

        if (currentUser.roles.includes("administrators") || currentUser.roles.includes("editor")) {
            buttons.push({
                key: "add",
                text: localize("SkapaNyResurs"),
                onClick: () => {
                    toggleShowNewResourceModal();
                },
                iconProps: { iconName: "Add" },
                className: "commandBarAddButton",
                theme: "primary"
            })
        }

        return buttons;
    }, [toggleShowNewResourceModal, currentUser, contentRef]);

    return (
        <div>
            <AppDisabledBanner type={localize("appSystemStatus")} />
            <div className={classNames("SystemStatusStartView")}>
                <PageHeader
                    title={localize("appSystemStatus")}
                    subText={helpContent?.text}
                    displaySearch={true}
                    onSearchQueryChange={debouncedSetSearchText}
                    buttons={headerButtons}
                />
                {isLoading && <SpintrLoader />}
                {!isLoading && (
                    <div className="categories">
                        {categories.map((c: ISystemStatusResourceCategory) => {
                            const isExpanded = expandedCategories.indexOf(c.key) > -1;

                            return (
                                <div
                                    key={c.key}
                                    className={[
                                        "category",
                                        "category-" + c.key,
                                        ["active", "planned"].indexOf(c.key) > -1 ? "has-background" : "",
                                        c.isActive ? "active" : "inactive",
                                        isExpanded ? "expanded" : "minimized"
                                    ].join(" ")}
                                >
                                    <UnstyledButton
                                        className="category-header"
                                        onClick={() => {
                                            if (isExpanded) {
                                                setExpandedCategories([...expandedCategories].filter(x => x !== c.key));
                                            } else {
                                                setExpandedCategories([
                                                    ...expandedCategories,
                                                    c.key
                                                ]);
                                            }
                                        }}>
                                        <div className="left">
                                            <Label color="primaryContent" size="body-2">{c.name}</Label>
                                            <div className="counter primaryBGColor">
                                                <Label color="white" weight="medium" size="body-3">
                                                    {c.resources.length}
                                                </Label>
                                            </div>
                                        </div>
                                        <Visage2Icon icon={isExpanded ? "arrow-up-2" : "arrow-down-1"} size="small" />
                                    </UnstyledButton>
                                    {isExpanded && (
                                        <div className="resources">
                                            {c.resources.flatMap((r) => r.status !== SpintrTypes.SystemStatusType.Ongoing && r.status !== SpintrTypes.SystemStatusType.Planned
                                                ? [(
                                                    <div key={r.id}>
                                                        <SystemStatusResource
                                                            key={r.id}
                                                            resource={r}
                                                            onDeclineSuggestion={(id: number) => {
                                                                setData([...data].filter(x => x.id !== id));
                                                            }}
                                                            onAcceptSuggestion={(id: number) => {
                                                                setData(data.map((item) => {
                                                                    if (item.id === id) {
                                                                        return {
                                                                            ...item,
                                                                            isSuggestion: false
                                                                        }
                                                                    }

                                                                    return item;
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                )] : r.activeSystemStatuses
                                                        .filter((s) => s.status === SpintrTypes.SystemStatusType.Ongoing || s.status === SpintrTypes.SystemStatusType.Planned)
                                                        .map((s) => (
                                                            <div key={s.id}>
                                                                <SystemStatus resource={r} systemStatus={s} />  
                                                            </div>
                                                        ))
                                            )}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
                {!isLoading && categories.length === 0 && (
                    <div className="spintr-list-empty-list">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                            {localize("IngaPoster")}
                        </Label>
                    </div>
                )}
            </div>
            <Modal
                className="spintr-modal modalWithPopupHeader system-status-resource-modal"
                isOpen={showNewResourceModal}
                onDismiss={() => {
                    setShowNewResourceModal(false);
                }}
            >
                <PopupHeader
                    text={localize("SYSTEM_STATUS_RESOURCES_MODAL_HEADER_" + (newResource?.id ? "EDIT" : "CREATE"))}
                    onClose={() => {
                        setShowNewResourceModal(false);
                    }}
                />
                <div className="popup-inner">
                    <SystemStatusForm
                        id={newResource?.id}
                        onDone={() => {
                            setShowNewResourceModal(false);
                            fetch();
                        }}
                        onCancel={() => {
                            setShowNewResourceModal(false);
                        }} />
                </div>
            </Modal>
        </div>
    )
};

export default SystemStatusStartView;
