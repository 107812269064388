import moment from "moment";
import { Icon as FabricIcon, Shimmer, ShimmerElementsGroup, ShimmerElementType, TooltipHost } from "@fluentui/react";
import React from "react";
import { localize } from "src/l10n";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import "./UserCard.scss";
import Color from "src/style/colors/Color";
import { createConversation } from "src/chat/api";
import { addRemoteConversation, setTabState } from "src/chat";
import { connect } from "react-redux";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    item?: any;
    isShimmer?: boolean;
    index?: number;
    renderActionMenu?: any;
    history: any;
    instanceColor?: string;
    showBorder?: boolean;
    dispatch?: any;
    currentUserId?: number;
    disablePersonChat?: boolean;
    hideUserCardFields?: boolean;
    onTextPage?: boolean;
}

class UserCard extends React.Component<IProps> {
    getCustomElements1() {
        return (
            <ShimmerElementsGroup
                shimmerElements={[
                    { type: ShimmerElementType.circle, height: 70, width: 70 },
                ]}
            />
        );
    }

    getCustomElements2() {
        return (
            <ShimmerElementsGroup
                flexWrap={true}
                shimmerElements={[
                    { type: ShimmerElementType.line, height: 10, verticalAlign: 'bottom' },
                    { type: ShimmerElementType.gap, height: 0, width: 0 },
                    { type: ShimmerElementType.line, height: 10 },
                    { type: ShimmerElementType.gap, height: 0, width: 0 },
                    { type: ShimmerElementType.line, height: 10 },
                    { type: ShimmerElementType.gap, height: 0, width: 0 },
                    { type: ShimmerElementType.line, height: 10 },
                    { type: ShimmerElementType.gap, height: 0, width: 0 },
                    { type: ShimmerElementType.line, height: 10 },
                ]}
            />
        );
    }

    renderButtons() {
        let icons: any[] = [];

        if (this.props.item.phone) {
            icons.push({
                title: localize("Telefonnummer") + ": " + this.props.item.phone,
                icon: "Call",
                onClick: () => {
                    //window.open("tel:" + this.props.item.phone, "_blank");
                    window.location.href = "tel:" + this.props.item.phone;
                }
            });
        }

        if (!this.props.item.phone &&
            this.props.item.cellphone) {
            icons.push({
                title: localize("Mobilnummer") + ": " + this.props.item.cellphone,
                icon: "Call",
                onClick: () => {
                    //window.open("tel:" + this.props.item.cellphone, "_blank");
                    window.location.href = "tel:" + this.props.item.cellphone;
                }
            });
        }

        if (!this.props.disablePersonChat &&
            !this.props.item.isBlocked &&
            !this.props.item.isBlockedBy &&
            !this.props.hideUserCardFields) {
            icons.push({
                title: localize("CHAT_WITH") + " " + this.props.item.name,
                icon: "message",
                onClick: () => {
                    const openConversation = (c: any) => {
                        this.props.dispatch(addRemoteConversation(c));
                        this.props.dispatch(setTabState(c.id, true, false));
                    }

                    if (!this.props.item.conversationId) {
                        const defaultUser: Spintr.IUser = {
                            id: 0,
                            name: "",
                            imageUrl: "",
                            info: "",
                            typeName: "",
                            type: 0
                        }

                        const participants: Spintr.IUser[] = [{
                            ...defaultUser,
                            id: this.props.item.id,
                        }, {
                            ...defaultUser,
                            id: this.props.currentUserId,
                        }];

                        createConversation(participants).then((response) => {
                            openConversation(response.data);
                        }).catch(() => { });
                    } else {
                        api.get("/api/v1/conversations/" + this.props.item.conversationId).then((response) => {
                            openConversation(response.data);
                        }).catch(() => { });
                    }
                }
            });
        }

        const hideUserEmailAddresses = false;

        if (!hideUserEmailAddresses &&
            this.props.item.email) {
            icons.push({
                title: localize("Epost") + ": " + this.props.item.email,
                icon: "sms",
                onClick: () => {
                    //window.open("mailto:" + this.props.item.email, "_blank");
                    window.location.href = "mailto:" + this.props.item.email;
                }
            });
        }

        // icons.push({
        //     title: localize("Profil"),
        //     icon: "FollowUser",
        //     onClick: () => {

        //     }
        // });

        if (icons.length > 0) {
            return (
                <div className="UserCard-Buttons">
                    {
                        icons.map((icon: any, index: number) => {
                            return (
                                <TooltipHost content={icon.title} key={index}>
                                    <UnstyledButton className="contact-button" onClick={icon.onClick} title={icon.title} style={{
                                        backgroundColor: Color.fromHex(this.props.instanceColor).customLighten().toString("hex")
                                    }}>
                                        <Visage2Icon icon={icon.icon} color="light-blue" />
                                    </UnstyledButton>
                                </TooltipHost>
                            )
                        })
                    }
                </div>
            )
        }
    }

    shouldComponentUpdate(nextProps: IProps) {
        if (!!this.props.item !== !!nextProps.item) {
            return true;
        }

        if (this.props.isShimmer !== nextProps.isShimmer) {
            return true;
        }

        if (!!this.props.item &&
            !!this.props.item.rights &&
            !!nextProps.item &&
            !!nextProps.item.rights &&
            (this.props.item.rights.locked !== nextProps.item.rights.locked ||
                this.props.item.rights.deleted !== nextProps.item.rights.deleted)) {
            return true;
        }

        return false;
    }

    render() {
        const classes = ["UserCard", "SpintrCard"];

        if (this.props.showBorder) {
            classes.push("show-border");
        }

        if (this.props.isShimmer) {
            classes.push("shimmer");
        }

        if (this.props.isShimmer) {
            return (
                <div className={classes.join(" ")} key={this.props.index}>
                    <div className="image-wrapper">
                        <Shimmer customElementsGroup={this.getCustomElements1()} />
                    </div>
                    <div className="UserCard-bottom">
                        <div className="UserCard-right">
                            <Shimmer customElementsGroup={this.getCustomElements2()} />
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.props.item) {
            return null;
        }

        const roleString = !!this.props.item.roles ?
            this.props.item.roles.map((role: any) => role.name)
                .join(", ") : "";

        const i = Color.fromHex(this.props.instanceColor).toRgba();

        return (
            <UnstyledButton className={classes.join(" ")} key={this.props.item.id} onClick={() => {
                this.props.history.push({
                    pathname: "/profile/" + this.props.item.id
                })
            }}>
                <div className="coverImage-wrapper" style={{
                    backgroundImage: !!this.props.item.coverImage ?
                        `url(${this.props.item.coverImage})` :
                        `linear-gradient(120deg, rgba(${i.red}, ${i.green}, ${i.blue},0.25) 0%, rgba(${i.red}, ${i.green}, ${i.blue},0.20) 100%)`,
                    backgroundPosition: "center"
                }} />
                <div className="image-wrapper">
                    <SpintrUser
                        name={this.props.item.name}
                        imageUrl={this.props.item.image}
                        // state={this.props.item.state}
                        hideText={true}
                        personalName={true}
                        size={70} />
                </div>
                <div className="UserCard-bottom">
                    <div className="UserCard-right">
                        <div className="UserCard-title">
                            <Label size="h4" color="dark-grey" as="span" weight="medium">{this.props.item.name}</Label>
                            {this.props.item.rights.locked && <FabricIcon iconName="LockSolid" title={localize("Last")} />}
                            {this.props.item.rights.deleted && <FabricIcon iconName="BlockedSolid" title={localize("Inaktiverad")} />}
                        </div>
                        {!!this.props.renderActionMenu && this.props.renderActionMenu()}
                        {
                            !this.props.hideUserCardFields &&
                            <>
                                {
                                    !!roleString && (
                                        <Label className="UserCard-infoRow" size="body-2" color="mid-grey">{roleString}</Label>
                                    )
                                }
                                {
                                    !!this.props.item.departmentName && (
                                        <Label className="UserCard-infoRow" size="body-2" color="mid-grey">{this.props.item.departmentName}</Label>
                                    )
                                }
                                {
                                    !!this.props.item.CustomSetting03 && (
                                        <Label className="UserCard-infoRow" size="body-2" color="mid-grey">{this.props.item.CustomSetting03}</Label>
                                    )
                                }
                                {
                                    !!this.props.item.lastSeen && (
                                        <Label className="UserCard-infoRow lastSeen" size="body-3" color="mid-grey">{localize("SenastSedd") + " " + moment(this.props.item.lastSeen).fromNow()}</Label>
                                    )
                                }
                            </>
                        }
                        {
                            this.renderButtons()
                        }
                    </div>
                </div>
            </UnstyledButton>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    currentUserId: state.profile.active.id,
    disablePersonChat: state.instance.get("disablePersonChat"),
    hideUserCardFields: props.onTextPage && state.instance.get("hideUserCardFields")
});

// @ts-ignore
export default connect(mapStateToProps)(UserCard);
