import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import api from "src/api";
import { setSidebarMode, VisageSidebarMode, setSidebarDrawerId, VisageSidebarDrawerId } from "src/sidebar";
import { setDisableWrapperBackground } from "src/ui";
import { setSystemStatusUnreadCount } from "./actions";
import { Route, Switch } from "react-router";
import SystemStatusStartView from "./SystemStatusStartView";
import SystemStatusView from "./SystemStatusView";

const SystemStatusRootView = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSidebarMode(VisageSidebarMode.drawer));
        dispatch(setSidebarDrawerId(VisageSidebarDrawerId.systemStatus));
        dispatch(setSystemStatusUnreadCount(0));

        api.put("/api/v1/operatinginfo/updatelastseen");
    });

    const routes = useMemo(() => {
        return [
            { path: "/operations/:id", component: SystemStatusView },
            { path: "/operations", component: SystemStatusStartView },
        ]
    }, []);

    return (
        <div className="SystemStatusRootView">
            <Switch>
                {routes.map((route) => (
                    <Route
                        key={`SystemStatusRootViewRoute.${route.path}`}
                        path={route.path}
                        component={route.component}
                    />
                ))}
            </Switch>
        </div>
    )
}

export default SystemStatusRootView;