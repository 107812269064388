import React, { Component } from "react";
import { connect } from "react-redux";
import { SocialFeedType } from "src/social-feed";
import { SpintrSocialFeed } from "src/spintr/components";
import { IApplicationState } from "src/spintr/reducer";
import { FillHeight, Scrollable } from "src/ui/components";

interface IProps {
    group: any;
    dispatch?: any;
}

class GroupFeedView extends Component<IProps> {
    public render() {
        const { group } = this.props;
        return (
            <div className="GroupFeedView GroupContentView feed">
                <div style={{ maxWidth: "100%" }}>
                    <SpintrSocialFeed
                        feedId={group.feedId}
                        feedType={SocialFeedType.Group}
                        galleryId={group.galleryId}
                        groupId={group.id}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
});

export default connect(mapStateToProps)(GroupFeedView);
