import React, { Component } from "react";
import ShowMoreText from "react-show-more-text";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import Label from "./Label/Label";
import SpintrLoader from "./Loader";
import "./SpintrHelpText.scss";

interface Props {
    id: number;
    alwaysDisplayAll?: boolean;
}

interface State {
    isLoading: boolean;
    helpText: string;
}

class SpintrHelpText extends Component<Props, State> {
    state = {
        isLoading: true,
        helpText: undefined,
    };

    fetch = () => {
        api.get(`/api/v1/help/${this.props.id}`).then((response) => {
            this.setState({ helpText: response.data.text, isLoading: false });
        }).catch(() => {
            this.setState({ isLoading: false });
        });
    };

    componentDidMount = () => {
        this.fetch();
    };

    render = () => {
        const { helpText } = this.state;
        if (this.state.isLoading) return <SpintrLoader />;

        if (helpText && helpText.length > 0) {
            return (
                <div className="SpintrHelpText">
                    {!this.props.alwaysDisplayAll && (
                        <ShowMoreText
                            lines={1}
                            more={localize("VisaMer")}
                            less={localize("VisaMindre")}
                            expanded={false}
                            width={480}
                        >
                            <Label size="body-2"
                                dangerouslySetInnerHTML={{
                                    __html: helpText,
                                }}
                            />
                        </ShowMoreText>
                    )}
                    {this.props.alwaysDisplayAll && (
                        <div className="TinyFormattedContent"
                            dangerouslySetInnerHTML={{
                                __html: helpText,
                            }}
                        />
                    )}
                </div>
            );
        } else return null;
    };
}

export default SpintrHelpText;
