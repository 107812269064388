import { Pivot, PivotItem, PrimaryButton } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import AcademyLeaderboard from "src/academy/AcademyLeaderboard/AcademyLeaderboard";
import AcademyCourseList from "src/academy/courses/AcademyCourseList/AcademyCourseList";
import { IAcademyCourse } from "src/academy/types";
import { localize } from "src/l10n";
import { setSidebarMode, VisageSidebarMode } from "src/sidebar";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, ContentImageViewerAndSelector, Label, PageHeader } from "src/ui";
import AppDisabledBanner from "src/ui/components/AppDisabledBanner/AppDisabledBanner";
import SpintrLoader from "src/ui/components/Loader";
import SpintrHelpText from "src/ui/components/SpintrHelpText";
import "./AcademyView.scss";

interface IProps {
    appEnabled?: boolean;
    appMode?: boolean;
    academyAppName?: string;
    academyAppHeaderImageUrl?: string;
    history?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
}

interface IState {
    isLoadingCourses: boolean;
    isLoadingLeaderboard: boolean;
    activeCourses: IAcademyCourse[];
    availableCourses: IAcademyCourse[];
    completedCourses: IAcademyCourse[];
    pinnedCoursesAndGuides: IAcademyCourse[];
    guides: IAcademyCourse[];
}

const AcademyView = (props: IProps) => {
    const [state, setState] = useState<IState>({
        isLoadingCourses: true,
        isLoadingLeaderboard: true,
        activeCourses: [],
        availableCourses: [],
        completedCourses: [],
        pinnedCoursesAndGuides: [],
        guides: [],
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!props.appEnabled) {
            return props.history.push("/404");
        }

        fetchCourses();

        dispatch(setSidebarMode(VisageSidebarMode.noMenu));

        return () => {
            dispatch(setSidebarMode(VisageSidebarMode.menu));
        };
    }, []);

    const fetchCourses = useCallback(() => {
        api.get("/api/v1/academy/courses")
            .then((response: AxiosResponse<IAcademyCourse[]>) => {
                setState((s: IState) => ({
                    ...s,
                    activeCourses: response.data.filter(
                        (x) => x.userProgress.isInitialized && !x.userProgress.isCompleted && !x.isGuide
                    ),
                    availableCourses: response.data.filter(
                        (x) => !x.userProgress.isInitialized && !x.userProgress.isCompleted && !x.isGuide
                    ),
                    completedCourses: response.data.filter((x) => x.userProgress.isCompleted && !x.isGuide),
                    pinnedCoursesAndGuides: response.data
                        .filter((x) => !x.userProgress.isCompleted && x.isPinned)
                        .sort((a, b) => a.name.localeCompare(b.name)),
                    guides: response.data.filter((x) => x.isGuide),
                    isLoadingCourses: false,
                }));
            })
            .catch(() => {});
    }, []);

    return (
        <div className="AcademyView">
            <Helmet>
                <title>{localize(props.academyAppName, true)}</title>
            </Helmet>
            <AppDisabledBanner type={localize(props.academyAppName, true)} />
            {!props.appMode && <PageHeader title={localize(props.academyAppName, true)} />}
            <ContentImageViewerAndSelector imageUrl={props.academyAppHeaderImageUrl} height={216} editMode={false} />
            <SpintrHelpText id={11} alwaysDisplayAll />
            <AcademyCourseList courses={state.pinnedCoursesAndGuides} big onPinToggle={fetchCourses} />
            {state.isLoadingCourses && <SpintrLoader />}
            {!state.isLoadingCourses && (
                <Pivot overflowBehavior={"menu"}>
                    <PivotItem
                        headerText={localize("AVAILABLE_PLURAL")}
                        headerButtonProps={{ ["data-introduction"]: "available" }}
                    >
                        {state.activeCourses.length > 0 && (
                            <AcademyCourseList
                                courses={state.activeCourses}
                                title={localize("IN_PROGRESS_PLURAL")}
                                onPinToggle={fetchCourses}
                            />
                        )}
                        {(state.availableCourses.length > 0 ||
                            ((props.isAdmin || props.isEditor) && !props.appMode)) && (
                            <AcademyCourseList
                                courses={state.availableCourses}
                                isFirstCourse={
                                    state.activeCourses.filter((c) => !c.demo).length +
                                        state.availableCourses.filter((c) => !c.demo).length ===
                                    0
                                }
                                canCreateCourses={(props.isAdmin || props.isEditor) && !props.appMode}
                                onPinToggle={fetchCourses}
                                search
                            />
                        )}
                        {state.activeCourses.length === 0 &&
                            state.availableCourses.length === 0 &&
                            !props.isAdmin &&
                            !props.isEditor && <Label>{localize("NO_COURSES_AVAILABLE")}</Label>}
                    </PivotItem>
                    {state.completedCourses.length > 0 && (
                        <PivotItem headerText={localize("COMPLETED_PLURAL")}>
                            <AcademyCourseList courses={state.completedCourses} onPinToggle={fetchCourses} />
                        </PivotItem>
                    )}
                    <PivotItem headerText={localize("AVAILABLE_GUIDES")}>
                        <AcademyCourseList
                            courses={state.guides}
                            guides
                            search
                            isFirstCourse={state.guides.length === 0}
                            canCreateCourses={(props.isAdmin || props.isEditor) && !props.appMode}
                            onPinToggle={fetchCourses}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText={localize("LEADERBOARD")}
                        headerButtonProps={{ ["data-introduction"]: "leaderboard" }}
                    >
                        <AcademyLeaderboard />
                    </PivotItem>
                </Pivot>
            )}
        </div>
    );
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    appEnabled: state.app.items.some((app) => app.enabled && app.id === SpintrTypes.SpintrApp.Academy),
    appMode: state.ui.appMode,
    academyAppName: state.instance.get("academyAppName"),
    academyAppHeaderImageUrl: state.instance.get("academyAppHeaderImageUrl"),
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
});

export default withRouter(connect(mapStateToProps)(AcademyView));
