import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { localize } from "src/l10n";
import { VisageSidebarDrawerId, VisageSidebarMode, setSidebarDrawerId, setSidebarMode } from "src/sidebar";
import api from "src/spintr/SpintrApi";
import { ContentWithInfoPanel, Label, Loader, PageHeader, SpintrUser, UnstyledButton, setDisableWrapperBackground } from "src/ui";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import "./SystemStatusView.scss";
import { SocialFeedContainer, SocialFeedType, visage2SocialPostTypes } from "src/social-feed";
import { SpintrTypes } from "src/typings";
import { Icon, Modal, Stack, StackItem } from "@fluentui/react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import classNames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Style } from "src/ui/helpers";
import { setCurrentResource, setSystemStatusUnreadCount } from "./actions";
import { InlineComposer } from "src/spintr/components/Composer";
import { circleSmall } from "src/ui/helpers/style";
import PopupHeader from "src/ui/components/PopupHeader";
import SystemStatusForm from "./SystemStatusForm";
import { Drawer } from "src/components";
import { Conditional } from "src/components/Conditional";
import SystemStatusTypePill from "./SystemStatusTypePill";
import getOpenTime, { getOpenTimes } from "./utils";
import { capitalizeFirstLetter } from "src/utils";
import moment from "moment";
import { ReactSVG } from "react-svg";
import { IContentHeaderButton } from "src/ui/components/PageHeader";
import { setConfirmPopup } from "src/popups/actions";

interface IRouteParams {
    id: string;
}

interface IProps {
    currentUser?: Spintr.IActiveUser;
    resource?: Spintr.ISystemStatusResource;
    viewMode?: SpintrTypes.ViewMode;
    isSmallViewMode?: boolean;
}

function getButtonText(viewMode: SpintrTypes.ViewMode, resource: Spintr.ISystemStatusResource) {
    return !resource?.userCanPost 
        ? localize("StallEnFraga")
        : (viewMode < SpintrTypes.ViewMode.SmallLaptop
            ? localize("Skapa")
            : localize("START_COMPOSER_PLACEHOLDER_TEXT_SYSTEM_STATUS").replace("{{X}}", resource?.title)
    );
}

const SystemStatusView: FunctionComponent<IProps> = (props) => {
    const dispatch = useDispatch();
    const params = useParams<IRouteParams>();
    const history = useHistory();
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState<string>();
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>();
    const [filterType, setFilterType] = useState<SpintrTypes.SystemStatusType>();
    const [showEditModal, setShowEditModal] = useState(false);
    const [archiveCount, setArchiveCount] = useState<any[]>();
    const [feedKey, setFeedKey] = useState(0);
    const previousResource = useRef<Spintr.ISystemStatusResource>();
    const resource = props.resource;
    const id = parseInt(params.id, 10);
    const searchTextSubject = useMemo(() => new Subject<string>(), []);
    const openTimes = useMemo(() => {
        if (!resource || resource.openTimes.length === 0) {
            return [];
        }

        return getOpenTimes(resource.openTimes);
    }, [resource]);

    useEffect(() => {
        dispatch(setCurrentResource(undefined));
        setFilterType(undefined);
        setSearchText("");
        setArchiveCount(undefined);

        api.get("/api/v1/systemstatuses/resources/" + id).then((response) => {
            const resource = response.data;

            if (resource.faq.length === 1) {
                resource.faq[0].expanded = true;
            }

            dispatch(setCurrentResource(resource));
        });
    }, [id]);

    useEffect(() => {
        dispatch(setDisableWrapperBackground(true));
        const subscription = searchTextSubject.pipe(debounceTime(250)).subscribe({
            next: (text) => {
                setDebouncedSearchText(text);
            },
        });

        return () => {
            dispatch(setDisableWrapperBackground(false));
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (
            resource?.id &&
            resource.id === previousResource?.current?.id &&
            (resource.title !== previousResource.current.title ||
                resource.imageUrl !== previousResource.current.imageUrl)
        ) {
            // Reloads the feed component
            setFeedKey(feedKey + 1);
        }

        if (resource !== undefined) {
            previousResource.current = resource;
        }
    }, [resource]);

    const toggleSystemInfo = () => setShowInfoPanel((prev) => !prev);

    const toggleExpanded = (idx) => {
        const tempResource = {
            ...resource,
            faq: [...resource.faq],
        };

        tempResource.faq[idx] = {
            ...tempResource.faq[idx],
            expanded: !tempResource.faq[idx].expanded,
        };

        dispatch(setCurrentResource(tempResource));
    };

    const subText = useMemo(() => {
        if (!resource) {
            return "";
        }

        return getOpenTime(resource.openTimes);
    }, [resource]);

    const renderSystemInfo = () => (
        <div className="right">
            <div className="system-info">
                <Stack className="header" horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Label size="h4" weight="medium">{localize("SYSTEM_INFORMATION")}</Label>
                    {props.isSmallViewMode && (
                        <UnstyledButton onClick={toggleSystemInfo}>
                            <Icon iconName="ChromeClose" />
                        </UnstyledButton>
                    )}
                </Stack>
                <div className="content">
                    {resource.description && (
                        <div className="description">
                            <Label size="h6" color="mid-grey" weight="medium" className="title">
                                {localize("Beskrivning")}
                            </Label>
                            <Label size="body-2" color="grey" className="resource-description">
                                {resource.description}
                            </Label>
                        </div>
                    )}
                    {openTimes.length > 0 && (
                        <div className="openTimes">
                            <Label size="h6" color="mid-grey" weight="medium" className="title">
                                {localize("OPEN_TIMES")}
                            </Label>
                            <div className="days">
                                {openTimes.map((ot: Spintr.IOpenTimeDay) => (
                                    <div className="openTimeDay" key={ot.dayOfWeek}>
                                        <ReactSVG
                                            src={`/images/ellipse.svg`}
                                        />
                                        <Label size="body-2" color="primaryContent">
                                            {capitalizeFirstLetter(localize("DayOfWeek" + ot.dayOfWeek)) + ": "}
                                            {!ot.isOpen ?
                                                localize("Stangd") :
                                                (moment(ot.startTime).format("HH:mm") + " - " + moment(ot.endTime).format("HH:mm"))}
                                        </Label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {resource.owners.length > 0 && (
                        <div className="owners content-box">
                            <Label size="h6" color="mid-grey" weight="medium" className="title">
                                {localize("SYSTEM_OWNERS")}
                            </Label>
                            {resource.owners.map((owner) => (
                                <SpintrUser
                                    key={owner.id}
                                    name={owner.name}
                                    subText={owner.info}
                                    imageUrl={owner.image}
                                    nameLink={"/profile/" + owner.id}
                                    personalName
                                />
                            ))}
                        </div>
                    )}
                    {resource.faq.length > 0 && (
                        <div className="faq">
                            {resource.faq.map((faq, idx) => (
                                <div key={faq.id} className={faq.expanded ? "expanded" : "minimized"}>
                                    <UnstyledButton onClick={() => toggleExpanded(idx)}>
                                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                                            <Label size="h6" color="dark-grey" weight="medium">
                                                {faq.question}
                                            </Label>
                                            <Visage2Icon icon={faq.expanded ? "minus" : "add"} color="black" />
                                        </Stack>
                                    </UnstyledButton>

                                    {faq.expanded && (
                                        <Label size="h6" color="grey" className="answer">
                                            {faq.answer}
                                        </Label>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const createButtonText = getButtonText(props.viewMode, resource);

    const getStatusButton = () => {
        const totalStatusCount = props.resource.doneCount + props.resource.plannedCount + props.resource.ongoingCount;

        const text = totalStatusCount < 2 ? localize("Skapad") :
            props.resource.status === SpintrTypes.SystemStatusType.AllClear ?
                "ALL_CLEAR" :
                props.resource.status === SpintrTypes.SystemStatusType.Ongoing ?
                    "Pagaende" :
                    props.resource.status === SpintrTypes.SystemStatusType.Planned ?
                        "Planerad" :
                        "Avklarad";

        return (
            <SystemStatusTypePill
                statusType={resource.status}
                isInitialStatus={totalStatusCount < 2}
                big
                ballOnly />
        )
    };

    const buttons : IContentHeaderButton[] = useMemo(() => {
        if (!props.resource) {
            return [];
        }

        const result: IContentHeaderButton[] = [];

        if (props.resource?.userCanPost) {
            result.push({
                key: "add",
                text: createButtonText,
                onClick: () => {
                    const inlineCompsoerButtonEl = document.querySelector("#InlineComposerButton") as HTMLElement;

                    if (inlineCompsoerButtonEl) {
                        inlineCompsoerButtonEl.click();
                    }
                },
                iconProps: { iconName: "Add" },
                className: "commandBarAddButton",
                theme: "primary"
            });
        }

        return result;
    }, [props.resource])

    if ((id && !resource) || isLoading) {
        return <Loader />;
    }

    return (
        <div className="SystemStatusView">
            <ContentWithInfoPanel
                renderInfoPanel={renderSystemInfo}
                template={1}
                visagePageStyle={true}
            >
                <Conditional condition={!showInfoPanel}>
                    <div className="left">
                        <div className="header">
                            <PageHeader
                                actionMenuCategories={[
                                    ...(!props.isSmallViewMode ? [] : [{
                                        items: [{
                                            text: localize("VisaMeta"),
                                            onClick: toggleSystemInfo,
                                        }]
                                    }]),
                                    ...(!props.resource?.userCanEdit ? [] : [{
                                        title: localize("Redaktor"),
                                        items: [{
                                            key: "edit",
                                            text: localize("Redigera"),
                                            onClick: () => setShowEditModal(true),
                                        }, {
                                            key: "delete",
                                            text: localize("TaBort"),
                                            onClick: () => {
                                                dispatch(setConfirmPopup({
                                                    isOpen: true,
                                                    title: localize("RaderaInnehall"),
                                                    message: localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase()),
                                                    onConfirm: () => {
                                                        setIsLoading(true);

                                                        api.delete("/api/objects/" + id).then(() => {
                                                            history.push("/operations");
                                                        }).catch(() => {
                                                            setIsLoading(false);
                                                        });
                                                    }
                                                }));
                                            }
                                        }]
                                    }])
                                ]}
                                buttons={buttons}
                                displayBackButton={props.isSmallViewMode}
                                onBackButtonClick={() => history.push("/operations")}
                                renderTitle={() => {
                                    return (
                                        <>
                                            <SpintrUser
                                                name={resource.title}
                                                subText={subText}
                                                imageUrl={resource?.imageUrl}
                                                personalName={false}
                                            />
                                            {getStatusButton()}
                                        </>
                                    )
                                }}
                            />
                            <InlineComposer
                                key={feedKey}
                                feedId={
                                    resource?.feedId || props.currentUser.systemStatusResources?.[0]?.feedId
                                }
                                identity={
                                    resource?.userCanPost
                                        ? {
                                                id:
                                                    resource?.id ||
                                                    props.currentUser.systemStatusResources?.[0]?.id,
                                                name:
                                                    resource?.title ||
                                                    props.currentUser.systemStatusResources?.[0]?.title,
                                                images: {
                                                    feedComposer:
                                                        resource?.imageUrl ||
                                                        props.currentUser.systemStatusResources?.[0]
                                                            ?.imageUrl,
                                                },
                                            }
                                        : undefined
                                }
                                placeholderColor="dark-grey"
                                type={
                                    resource?.userCanPost
                                        ? visage2SocialPostTypes.find(
                                                (t) => t.type === SpintrTypes.StatusType.SystemStatus
                                            )
                                        : undefined
                                }
                                types={["image"]}
                                disableEffects={true}
                                spintrUserSize={circleSmall}
                                hidePostTypes
                            />
                        </div>
                        <div className="feed">
                            <SocialFeedContainer
                                key={feedKey}
                                feedId={resource?.feedId}
                                feedType={SocialFeedType.SystemStatus}
                                systemStatusType={filterType}
                                searchText={debouncedSearchText}
                                emptyText={localize("EMPTY_SYSTEM_STATUS")}
                            />
                        </div>
                    </div>
                </Conditional>
                <Conditional condition={showInfoPanel}>
                    {renderSystemInfo()}
                </Conditional>
            </ContentWithInfoPanel>
            <Modal
                className="spintr-modal modalWithPopupHeader system-status-resource-modal"
                isOpen={showEditModal}
                onDismiss={() => {
                    setShowEditModal(false);
                }}
            >
                <PopupHeader
                    text={localize("SYSTEM_STATUS_RESOURCES_MODAL_HEADER_EDIT")}
                    onClose={() => {
                        setShowEditModal(false);
                    }}
                />
                <div className="popup-inner">
                    <SystemStatusForm
                        id={resource.id}
                        onDone={() => {
                            setShowEditModal(false);
                        }}
                        onCancel={() => {
                            setShowEditModal(false);
                        }} />
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
    resource: state.systemStatus.currentResource,
    viewMode: state.ui.viewMode,
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps)(SystemStatusView);
