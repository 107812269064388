import React, { useCallback, useEffect } from 'react';
import { IApplicationState } from '../reducer';
import { connect } from 'react-redux';
import { Style, throttle } from 'src/ui/helpers';

interface IProps {
    formFooterBarVisible?: boolean;
    appMode?: boolean;
    visage2FullscreenMode?: boolean;
}

const SpintrScrollChecker = (props: IProps) => {
    const setHeaderHeight = useCallback(() => {
        const element = document.querySelector<HTMLElement>(".VisageSidebar");

        if (!element) {
            return;
        }

        let sidebarHeight = document.documentElement.clientHeight;

        const headerIsHidden = props.appMode || props.visage2FullscreenMode;

        if (!headerIsHidden) {
            const noScroll = (document.querySelector<HTMLElement>(".mainWrap")?.clientHeight || 0) === document.documentElement.clientHeight - Style.headerHeight;
            const pxOfHeaderVisible = noScroll ? Style.headerHeight : Math.max(Style.headerHeight - window.scrollY, 0);

            sidebarHeight -= (pxOfHeaderVisible + Style.getSpacing(8));
        }

        if (props.formFooterBarVisible) {
            sidebarHeight -= Style.formFooterBarHeight;
        }

        sidebarHeight -= 1;

        const styleValue = sidebarHeight.toString() + "px";

        if (element.style.height === styleValue) {
            return;
        }

        element.style.height = styleValue;
    }, [props.formFooterBarVisible, props.appMode, props.visage2FullscreenMode]);
    
    const throttledScrollHandler = throttle(setHeaderHeight, 100, undefined);

    useEffect(() => {
        setHeaderHeight();
    }, [props.formFooterBarVisible, props.appMode, props.visage2FullscreenMode]);

    useEffect(() => {
        window.addEventListener("scroll", throttledScrollHandler);
        return () => window.removeEventListener("scroll", throttledScrollHandler);
    }, [props.formFooterBarVisible, props.appMode, props.visage2FullscreenMode]);

    useEffect(() => {
        window.addEventListener("resize", throttledScrollHandler);
        return () => window.removeEventListener("resize", throttledScrollHandler);
    }, [props.formFooterBarVisible, props.appMode, props.visage2FullscreenMode]);

    return null;
};

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    formFooterBarVisible: state.ui.formFooterBarVisible,
    appMode: state.ui.appMode,
    visage2FullscreenMode: state.ui.visage2FullscreenMode,
});

export default connect(mapStateToProps)(SpintrScrollChecker);