import React, { useState } from "react";
import AcademyCourseListItem from "src/academy/courses/AcademyCourseListItem/AcademyCourseListItem";
import { IAcademyCourse } from "src/academy/types";
import { Label } from "src/ui";
import "./AcademyCourseList.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { localize } from "src/l10n";
import { Link, useHistory } from "react-router-dom";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import { CommandBar } from "@fluentui/react";
import { useSelector } from "react-redux";
import { SpintrTypes } from "src/typings";

interface IProps {
    courses: IAcademyCourse[];
    title?: string;
    big?: boolean;
    guides?: boolean;
    canCreateCourses?: boolean;
    isFirstCourse?: boolean;
    search?: boolean;
    onPinToggle?: () => void;
}

const AcademyCourseList = (props: IProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [category, setCategory] = useState<number>(0);

    const history = useHistory();

    const viewMode = useSelector<Spintr.AppState, Spintr.ViewMode>(
        (appState) => appState.ui.viewMode,
    );

    const searchEvent = (ev, val) => {
        setSearchText(val);
    };

    return (
        <div className="AcademyCourseList">
            {props.search && (
                <CommandBar
                    className="CommandBar-GrowSearch"
                    items={[
                        {
                            key: "search",
                            onRender: () => (
                                <SpintrSearch classes="searchBox" onChange={searchEvent} value={searchText} />
                            ),
                        },
                        {
                            key: "category",
                            name: localize("Kategori"),
                            subMenuProps: {
                                items: [
                                    {
                                        key: "0",
                                        id: 0,
                                        text: localize("Alla"),
                                        onClick: () => {
                                            setCategory(0);
                                        }
                                    },
                                    ...props.courses
                                        .filter((course) => course.category)
                                        .reduce(
                                            (acc, { category }) =>
                                                acc.some((cat) => cat.id === category.id) ? acc : [...acc, category],
                                            []
                                        )
                                        .map((category) => ({
                                            key: category.id,
                                            text: category.name,
                                            onClick: () => {
                                                setCategory(category.id);
                                            }
                                        })),
                                ],
                                isBeakVisible: false,
                            },
                        },
                        ...(props.canCreateCourses
                            ? [
                                  {
                                      key: "create",
                                      className:
                                          viewMode < SpintrTypes.ViewMode.TabletPortrait
                                              ? ""
                                              : "create-wiki-button primaryBGColor primaryTextContrast commandBarAddButton",
                                      onRenderIcon: () => <Visage2Icon icon={"Add"} size="big" color="white" />,
                                      text: localize("SkapaNy"),
                                      subMenuProps: {
                                        items: [
                                            {
                                                key: "0",
                                                id: 0,
                                                text: localize("COURSE"),
                                                onClick: () => {
                                                    history.push("/admin/learning/create");
                                                }
                                            },
                                            {
                                                key: "1",
                                                id: 1,
                                                text: localize("GUIDE"),
                                                onClick: () => {
                                                    history.push("/admin/learning/create/guide")
                                                }
                                            }
                                        ]
                                      },
                                  },
                              ]
                            : []),
                    ]}
                />
            )}
            {props.title && (
                <Label weight="semi-bold">
                    {props.title}
                </Label>
            )}
            <div className="courses">
                {props.courses
                    .filter(
                        (course) =>
                            (category === 0 || course.category?.id === category) &&
                            (searchText.length === 0 ||
                                (searchText.length === 1 &&
                                    course.name.toLowerCase().startsWith(searchText.toLowerCase())) ||
                                (searchText.length > 1 && course.name.toLowerCase().includes(searchText.toLowerCase())))
                    )
                    .map((x: IAcademyCourse) => (
                        <AcademyCourseListItem key={x.id} course={x} big={props.big} onPinToggle={props.onPinToggle} />
                    ))}
                {props.canCreateCourses && (
                    <Link
                        className="AcademyCourseListItem create"
                        to={"/admin/learning/create" + (props.guides ? "/guide" : "")}
                    >
                        <Visage2Icon icon="add" color="visageMidBlue" size="big" />
                        <Label weight="bold" color="dark-grey">
                            {localize(
                                props.guides
                                    ? props.isFirstCourse
                                        ? "CREATE_FIRST_GUIDE"
                                        : "CREATE_A_GUIDE"
                                    : props.isFirstCourse
                                    ? "CREATE_FIRST_COURSE"
                                    : "CREATE_A_COURSE"
                            )}
                        </Label>
                        {props.isFirstCourse && !props.guides && (
                            <Label color="dark-grey">{localize("CREATE_FIRST_COURSE_DESC")}</Label>
                        )}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default AcademyCourseList;
