import "core-js"
import "regenerator-runtime/runtime";
import 'promise-polyfill/src/polyfill';
import '@fluentui/ie11-polyfills';
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons/lib/initializeFileTypeIcons";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { loadTheme } from "@fluentui/react/lib/Styling";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "../node_modules/video.js/dist/video-js.min.css";
import "./index.css";
import "./main.scss";
import "./reset.css";
import * as serviceWorker from "./serviceWorker";
import Spintr from "./Spintr";
import configureStore from "./spintr/store";
import Auth from "./auth/Auth";

if (navigator.platform.match('Mac') !== null) {
    document.body.setAttribute('class', document.body.className + ' MAC');
}

// @ts-ignore
const store = configureStore();

initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20210407.001/assets/icons/");
initializeFileTypeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20211104.001/assets/item-types/");
loadTheme({});

function ProtectedRoute(props) {
    if (!document.cookie.includes("LoggedIn=true")) {
        const currentPath = window.location.pathname;
        const encodedPath = encodeURIComponent(currentPath);

        return <Redirect to={"/sign-in?return=" + encodedPath} />;
    }

    return props.children;
}

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <Switch>
                <Redirect from="/logga-in" to="/sign-in" />
                <Route path="/sign-in" component={Auth} />
                <Route path="/password-recovery" component={Auth} />
                <Route path="/forgot-password" component={Auth} />
                <ProtectedRoute>
                    <Route path="*" render={props => <Spintr />} />
                </ProtectedRoute>
            </Switch>
        </Provider>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
