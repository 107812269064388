const onRecommendedAudienceSelected = (targets: any[], target: any) : any[] => {
    if (target.id < 1) {
        return [target];
    }

    targets = (targets || []);

    if (!!targets.find(x => x.id === target.id)) {
        return targets.filter(x => x.id !== target.id);
    }

    return [
        ...targets.filter((x) => x.id > 0 && x.id !== target.id),
        target
    ]
}

export default onRecommendedAudienceSelected;
