import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { AppPageContext } from "../AppPageContext";
import api from "src/api";
import { MarketplaceWidgetData } from "../MarketplaceWidgetData";
import { MarketplaceWidget } from "../MarketplaceWidget";

interface IProps extends IAppPageComponent {
    displayType: Spintr.WidgetType;
}

type State = {
    data?: any;
    isLoading: boolean;
};

function AppPageWidget(props: IProps): JSX.Element {
    const context = useContext(AppPageContext);
    const { displayType, requestName, requestVariables, text, variables } = props;
    const [state, setState] = useState<State>({ isLoading: false });

    const loadData = useCallback(async function _AppPageWidgetLoad() {
        setState((s) => ({
            ...s,
            isLoading: true,
        }));

        try {
            const response = await  api.post(
                `/api/v1/MarketplaceApplicationPage/${context.appId}/request`, {
                    requestName,
                    variables: requestVariables
                    ? requestVariables.reduce(
                        (acc, key) => {
                            acc[key] = (variables || {})[key];
    
                            return acc;
                        },
                        {},
                    )
                    : {}
                }
            );

            setState({
                data: response.data,
                isLoading: false,
            });
        }
        catch (reason) {
            setState((s) => ({
                ...s,
                isLoading: false,
            }));
        }
    }, [requestName, requestVariables, variables]);

    const widgetData = useMemo<Spintr.IMarketplaceWidgetDataResponse>(
        function _memoizeWidgetData() {
            if (!state.data) {
                return null;
            }

            return {
                data: state.data,
                displayType,
                name: text,
                variables,
            };
        },
        [displayType, state.data, text, variables],
    );

    useEffect(function _AppPageWidgetMount() {
        const loadInterval = setInterval(loadData, 20 * 1000);

        loadData();

        return function _AppPageWidgetUnmount() {
            clearInterval(loadInterval);
        };
    }, [loadData]);

    return (
        <div className="AppPageWidget">
            <MarketplaceWidget data={widgetData} />
        </div>
    );
};

export default AppPageWidget;
