import { Action } from "redux";
import ActionTypes from "./action-types";
import api from "src/spintr/SpintrApi";
import { AxiosError, AxiosResponse } from "axios";


export interface ISetCurrentResourceAction extends Action {
    currentResource: Spintr.ISystemStatusResource;
    type: ActionTypes.SetCurrentResource;
}

export interface ISetSystemStatusUnreadCountAction extends Action {
    unreadCount: number;
    type: ActionTypes.SetUnreadCount;
}

export interface IFetchSystemStatusUnreadCountAction extends Action {
    payload: Promise<AxiosResponse<number>>;
    type: ActionTypes.FetchUnreadCount;
}

export interface IFetchSystemStatusUnreadCountPendingAction extends Action {
    payload: Promise<AxiosResponse<number>>;
    type: ActionTypes.FetchUnreadCountPending;
}

export interface IFetchSystemStatusUnreadCountRejectedAction extends Action {
    payload: AxiosError;
    type: ActionTypes.FetchUnreadCountRejected;
}

export interface IFetchSystemStatusUnreadCountFulfilledAction extends Action {
    payload: AxiosResponse<number>;
    type: ActionTypes.FetchUnreadCountFulfilled;
}

export interface IAddPrioritySystemStatusAction extends Action {
    systemStatus: Spintr.ISocialPostBase;
    type: ActionTypes.AddPrioritySystemStatus;
}

export interface IFetchPrioritySystemStatusesAction extends Action {
    payload: Promise<AxiosResponse<Spintr.ISocialPostBase[]>>;
    type: ActionTypes.FetchPrioritySystemStatuses,
}

export interface IFetchPrioritySystemStatusesPendingAction extends Action {
    payload: Promise<AxiosResponse<Spintr.ISocialPostBase[]>>;
    type: ActionTypes.FetchPrioritySystemStatusesPending,
}

export interface IFetchPrioritySystemStatusesRejectedAction extends Action {
    payload: AxiosError;
    type: ActionTypes.FetchPrioritySystemStatusesRejected,
}

export interface IFetchPrioritySystemStatusesFulfilledAction extends Action {
    payload: AxiosResponse<Spintr.ISocialPostBase[]>;
    type: ActionTypes.FetchPrioritySystemStatusesFulfilled,
}

export interface IPostSystemStatusViewedAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse>;
    type: ActionTypes.PostSystemStatusViewed,
}

export interface IPostSystemStatusViewedPendingAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse>;
    type: ActionTypes.PostSystemStatusViewedPending,
}

export interface IPostSystemStatusViewedRejectedAction extends Action {
    meta: number;
    payload: AxiosError;
    type: ActionTypes.PostSystemStatusViewedRejected,
}

export interface IPostSystemStatusViewedFulfilledAction extends Action {
    meta: number;
    payload: AxiosResponse;
    type: ActionTypes.PostSystemStatusViewedFulfilled,
}

export type SetCurrentResourceHandler
    = (currentResource: Spintr.ISystemStatusResource) => ISetCurrentResourceAction;

export type AddPrioritySystemStatusHandler
    = (status: Spintr.ISocialPostBase) => IAddPrioritySystemStatusAction;

export type FetchPrioritySystemStatusesHandler
    = () => IFetchPrioritySystemStatusesAction;

export type PostSystemStatusViewedHandler
    = (id: number) => IPostSystemStatusViewedAction;

export type SetSystemStatusUnreadCountHandler
    = (unreadCount: number) => ISetSystemStatusUnreadCountAction;

export type FetchSystemStatusUnreadCountHandler
    = () => IFetchSystemStatusUnreadCountAction;

export const setCurrentResource: SetCurrentResourceHandler
    = (currentResource: Spintr.ISystemStatusResource) => ({
        currentResource,
        type: ActionTypes.SetCurrentResource
    });

export const setSystemStatusUnreadCount: SetSystemStatusUnreadCountHandler
    = (unreadCount: number) => ({
        unreadCount,
        type: ActionTypes.SetUnreadCount,
    });


export const addPrioritySystemStatus: AddPrioritySystemStatusHandler
    = (systemStatus: Spintr.ISocialPostBase) => ({
        systemStatus,
        type: ActionTypes.AddPrioritySystemStatus,
    });

export const fetchPrioritySystemStatuses: FetchPrioritySystemStatusesHandler
    = () => ({
        payload: api.get("/api/v1/systemstatuses/prioritized"),
        type: ActionTypes.FetchPrioritySystemStatuses,
    });

export const postSystemStatusViewed: PostSystemStatusViewedHandler
    = (id: number) => ({
        meta: id,
        payload: api.post(`/api/v1/systemstatuses/${id}/viewed`),
        type: ActionTypes.PostSystemStatusViewed,
    });

export const fetchSystemStatusUnreadCount: FetchSystemStatusUnreadCountHandler
    = () => ({
        payload: api.get("/api/v1/systemstatuses/unread"),
        type: ActionTypes.FetchUnreadCount,
    });

export type SystemStatusAction
    = ISetCurrentResourceAction
    | IAddPrioritySystemStatusAction
    | IFetchPrioritySystemStatusesAction
    | IFetchPrioritySystemStatusesPendingAction
    | IFetchPrioritySystemStatusesRejectedAction
    | IFetchPrioritySystemStatusesFulfilledAction;

export default SystemStatusAction;
