import React from "react";
import { Link, useHistory } from "react-router-dom";
import AcademyCourseCategories from "src/academy/course/AcademyCourseCategories/AcademyCourseCategories";
import AcademyImage from "src/academy/course/AcademyImage/AcademyImage";
import AcademyCourseMetaDataRow from "src/academy/course/AcademyCourseMetaDataRow/AcademyCourseMetaDataRow";
import AcademyCourseProgress from "src/academy/course/AcademyCourseProgress/AcademyCourseProgress";
import { IAcademyCourse } from "src/academy/types";
import { Label } from "src/ui";
import "./AcademyCourseListItem.scss";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import { useSelector } from "react-redux";
import api from "src/api";

interface IProps {
    course: IAcademyCourse;
    big?: boolean;
    hideActionMenu?: boolean;
    onPinToggle?: () => void;
}

// NOTE: Also used in feed for shared courses
const AcademyCourseListItem = (props: IProps) => {
    const isAdminOrEditor = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.profile.active.isAdmin || appState.profile.active.isEditor
    );

    const history = useHistory();

    return (
        <Link to={"/learning/" + props.course.id} className={["AcademyCourseListItem", ...(props.big ? ["big"] : [])].join(" ")}>
            <div className="AcademyCourseListItem-image">
                <AcademyImage imageUrl={props.course.imageUrl} />
                {!props.hideActionMenu && (
                    <StandardActionMenu
                        canShare
                        canEdit={isAdminOrEditor}
                        canPin={isAdminOrEditor}
                        onEditClick={() => {
                            history.push(`/admin/learning/${props.course.id}/edit`);
                        }}
                        onPinClick={async () => {
                            await api.post("/api/v1/objects/toggle-pin/" + props.course.id);
                            props.onPinToggle?.();
                        }}
                        isPinned={props.course.isPinned}
                        objectId={props.course.id}
                    />
                )}
            </div>
            <div className="AcademyCourseListItem-info">
                <div>
                    <Label size="h3" className="course-name" weight="semi-bold" color="dark-grey">{props.course.name}</Label>
                    {props.course.category && <AcademyCourseCategories categories={[props.course.category]}  />}
                    {props.course.description && <Label className="course-description" size="body-1" color="dark-grey">{props.course.description}</Label>}
                </div>
                <div>
                    <AcademyCourseMetaDataRow
                        lessonCount={props.course.statistics.lessonCount}
                        userCount={props.course.statistics.usersTotal}
                        language={props.course.language}
                    />
                    {props.course.userProgress.isInitialized && (
                        <AcademyCourseProgress userProgress={props.course.userProgress} />
                    )}
                </div>
            </div>
        </Link>
    )
}

export default AcademyCourseListItem;
