import { DefaultButton, ICommandBarItemProps, TooltipHost, TooltipHostBase, useWindow } from "@fluentui/react";
import classNames from "classnames";
import React, { Component, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/index";
import { ActionMenu, Label, UnstyledButton } from "src/ui";
import StandardActionMenu from "./ActionMenu/StandardActionMenu";
import { MandatoryText } from "./Forms";
import "./PageHeader.scss";
import SpintrHelpText from "./SpintrHelpText";
import { decodeHtmlEntities } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SmallHeading } from "src/components";
import SpintrSearch from "./SpintrList/SpintrSearch";
import CalypsoButton from "./Buttons/CalypsoButton/CalypsoButton";

export interface IContentHeaderButton {
    /**
     * @deprecated Idk
     */
    className?: string | undefined;
    key: string | number;

    /**
     * @deprecated Doesn't seem to be used
     */
    title?: string | undefined;

    text?: string;
    icon?: string;
    /**
     * @deprecated Idk
     */
    iconOnly?: any;
    iconProps?: any;
    onClick?: any;
    theme?: "light" | "primary";
    onRender?: () => ReactElement;
    subMenuProps?: any;
    reversed?: boolean;
}

export interface IPageHeaderProps {
    actionMenuCategories?: Spintr.IActionMenuCategory[];
    buttons?: IContentHeaderButton[];
    contentKey?: string;
    displayBackButton?: boolean;
    displayMandatoryText?: boolean;
    displaySearch?: boolean;
    formatSubTextHtml?: boolean;
    helpTextId?: number;
    hits?: number;
    lang?: string;
    onBackButtonClick?: () => void;
    onSearchQueryChange?: (value: string) => void;
    renderTitle?: any;
    standardActionMenuProps?: any;
    size?: spintrLabelSizes;
    subText?: string;
    title?: string;
    titleMenuCategories?: Spintr.IActionMenuCategory[];
    useShowMoreSubText?: boolean;
    forceExpandedSearch?: boolean;
}

interface IState {
    searchQuery: string;
    collapse: boolean;
}

const PageHeader = (props: IPageHeaderProps) => {
    const ref = useRef<HTMLDivElement>();
    
    const isSmallViewMode = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.ui.isSmallViewMode,
    );

    const [state, setState] = useState<IState>({
        searchQuery: "",
        collapse: isSmallViewMode
    });

    const getSubText = () => {
        return decodeHtmlEntities(props.subText);
    }

    const shouldCollapse = useCallback(() => {
        if (!ref.current) {
            return false;
        }

        if (ref.current.clientWidth === 0) {
            return false;
        }

        const wrapperWidth = ref.current.clientWidth;
        const contentWidth = 200 + ((props.buttons || []).length * 100);

        return contentWidth >= wrapperWidth;
    }, [ref.current, isSmallViewMode, props.buttons]);

    useEffect(() => {
        setTimeout(() => {
            const collapse = shouldCollapse();

            if (collapse !== state.collapse) {
                setState((s) => ({
                    ...s,
                    collapse
                }));
            }
        }, 500);
    }, [ref.current, state.collapse, shouldCollapse]);

    const actionMenuCategories = useMemo(() => {
        const categories = props.actionMenuCategories || [];

        if (!state.collapse || !props.buttons) {
            return categories;
        }

        const items = props.buttons.map((button) => ({
            ...button,
            key: typeof button.key === "string" ? button.key : button.key.toString(),
            text: button.text || button.title
        }));

        categories.unshift({
            items
        });

        return categories;
    }, [state.collapse, props.buttons, props.actionMenuCategories])

    return (
        <div
            className={classNames("pageHeader ", props.size, {
                "smallViewMode": isSmallViewMode
            })}
            lang={props.lang}
            ref={ref}
        >
            <div className="pageHeader-title">
                <div className="pageHeader-title-left">
                    {props.displayBackButton && (
                        <UnstyledButton onClick={props.onBackButtonClick} title={localize("GaTillbaka")}>
                            <Visage2Icon icon="arrow-left-2" />
                        </UnstyledButton>
                    )}
                    {!!props.renderTitle && props.renderTitle()}
                    {!props.renderTitle && props.title && (
                        <SmallHeading>{props.title}</SmallHeading>
                    )}
                    {props.titleMenuCategories && (
                        <ActionMenu categories={props.titleMenuCategories} icon="arrow-down-1" />
                    )}
                    {!!props.subText && (
                        <TooltipHost
                            className="marketplace-tooltip"
                            content={getSubText()}
                            style={{
                                maxWidth: 300
                            }}
                        >
                            <Visage2Icon icon="info-circle" size="small" />
                        </TooltipHost>
                    )}
                    {(props.title || props.renderTitle) && (
                        <div className="spacer" />
                    )}
                    {props.displaySearch && (
                        <SpintrSearch
                            value={state.searchQuery}
                            onChange={(event: React.ChangeEvent, value: string) => {
                                if (state.searchQuery === value) {
                                    return;
                                }

                                setState((s) => ({
                                    ...s,
                                    searchQuery: value
                                }));

                                props.onSearchQueryChange(value);
                            }}
                            onClear={() => {
                                if (state.searchQuery === "") {
                                    return;
                                }

                                setState((s) => ({
                                    ...s,
                                    searchQuery: ""
                                }));

                                props.onSearchQueryChange("");
                            }}
                            expandable={!props.forceExpandedSearch} />
                    )}
                </div>
                <div className="pageHeader-title-right">
                    {!state.collapse && props.buttons && props.buttons.map((button, index) => {
                        if (button.onRender) {
                            return button.onRender();
                        }

                        if (button.subMenuProps) {
                            return (
                                <div key={button.key || index} className="action-menu-wrapper">
                                    <ActionMenu
                                        categories={[{
                                            items: button.subMenuProps.items
                                        }]}
                                        renderButton={() => {
                                            return (
                                                <CalypsoButton
                                                    icon={button.icon || "arrow-down-1"}
                                                    title={localize("Meny")}
                                                    text={button.text}
                                                    theme={button.theme}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                            )
                        }

                        return (
                            <CalypsoButton
                                key={button.key || index}
                                icon={button.icon || button.iconProps?.iconName}
                                text={button.text}
                                title={button.text}
                                onClick={button.onClick}
                                theme={button.theme}
                                reversed={button.reversed} />
                        )
                    })}
                    {actionMenuCategories.length > 0 && (
                        <div className="action-menu-wrapper">
                            <ActionMenu
                                categories={actionMenuCategories}
                                renderButton={() => {
                                    return (
                                        <CalypsoButton icon="more-square" title={localize("Meny")} />
                                    )
                                }}
                            />
                        </div>
                    )}
                    {!!props.standardActionMenuProps && (
                        <StandardActionMenu
                            {...props.standardActionMenuProps}
                            renderButton={() => {
                                return (
                                    <CalypsoButton icon="more-square" title={localize("Meny")} />
                                )
                            }}
                        />
                    )}
                </div>
            </div>
            {props.hits !== undefined && (
                <Label size="body-2" className="pageHeader-hits">
                    {props.hits + " " + localize("Traffar").toLowerCase()}
                </Label>
            )}
            {!!props.displayMandatoryText && (
                <MandatoryText />
            )}
            {props.helpTextId !== undefined && (
                <Label className={"pageHeader-helpText"} size="body-2" as="div">
                    <SpintrHelpText id={props.helpTextId} />
                </Label>
            )}
        </div>
    );
}

export default PageHeader;