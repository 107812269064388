import { DefaultButton } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { localize } from "src/l10n";
import "./ReadReceiptButton.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import api from "src/spintr/SpintrApi";

interface IProps {
    id: number;
    isUnread: boolean;
    onClick: () => void;
    onSuccess: () => void;
}

const ReadReceiptButton = (props: IProps) => {
    const [isUnread, setIsUnread] = useState<boolean>(props.isUnread);

    const onClick = useCallback(() => {
        setIsUnread(false);
        props.onClick();

        api.post("/api/v1/statistics/post-read-receipt/" + props.id).then(props.onSuccess).catch(() => {});
    }, [isUnread]);

    return (
        <div className={["ReadReceiptButton", isUnread ? "unread" : "read"].join(" ")}>
            <DefaultButton
                disabled={!isUnread}
                onClick={onClick}
            >
                {!isUnread && (
                    <Visage2Icon icon="tick-circle" color="spintrGreen" />
                )}
                {localize(isUnread ? "chat_mark_read" : "MARKED_AS_READ")}
            </DefaultButton>
        </div>
    );
};

export default ReadReceiptButton;
