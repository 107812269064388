import React, { Component, ReactNode, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ChatTabContainer, setUnreadCountGroupConversations, setUnreadCountPersonConversations } from "src/chat";
import { fetchLangTags, localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import {
    ResponsiveDataLoader,
    ResponsiveHeader,
    ResponsiveMenu,
    ResponsiveProfile,
    SpintrHeader,
    SpintrWidthWatcher
} from "./spintr/components";
import { IApplicationState } from "./spintr/reducer";
import SpintrLoader from "./ui/components/Loader";
import "src/style/spintrNormalize.scss";
import "src/style/keyboardNavigation.scss";
import { fetchAlerts } from "src/emergency-alerts/actions";
import { ResponsiveSearch } from "./search";
import SpintrCustomStyleProvider from "./spintr/components/SpintrCustomStyleProvider";
import { Helmet } from "react-helmet";
import NotificationCard from "./ui/components/NotificationCard/NotificationCard";
import { getAppInsights } from './TelemetryService';
import TelemetryProvider from "./TelemetryProvider";
import { fetchApps } from "./app/actions";
import { getActiveUser, updateActiveUser } from "./profile/actions";
import { getInstance } from "./instance/actions";
import { getPrivileges } from "./privileges/actions";
import { VisageSidebar, VisageSidebarMode, setMenu } from "./sidebar";
import { setUnreadCount } from "./notifications/actions";
import { initializeMixpanel } from "./utils";
import OfflineBanner from "./ui/components/OfflineBanner/OfflineBanner";
import UpdateReloadBanner from "./ui/components/UpdateReloadBanner/UpdateReloadBanner";
import SpintrOverlay from "./spintr/components/SpintrOverlay";
import api, { setUserAgentExtension } from "./spintr/SpintrApi";
import VisageResponsiveSubmenu from "./sidebar/components/VisageSidebar/VisageResponsiveSubmenu";
import ReactionPicker from "./interactions/components/ReactionPicker";
import StartupGuides from "./ui/components/StartupGuides/StartupGuides";
import AppFeedbackBanner from "./ui/components/AppfeedbackBanner/AppFeedbackBanner";
import MobileAppBridge from "./spintr/components/MobileAppBridge/MobileAppBridge";
import SpintrNavigationBlocker from "./spintr/components/SpintrNavigationBlocker";
import SpintrLocationChecker from "./spintr/components/SpintrLocationChecker";
import classNames from "classnames";
import { SpintrTypes } from "./typings";
import VisageGradient from "./ui/components/VisageGradient";
import { startDeliverTrack } from "./utils/spintrStartDeliverfunctions";
import ResponsiveShortcuts from "./spintr/components/ResponsiveShortcuts/ResponsiveShortcuts";
import { MfaWizard } from "./profile/components";
import { fetchImportantArticles } from "./spintr/components/InformationFeed/actions";
import { RealtimeContextProvider } from "./contexts";
import SpintrScrollChecker from "./spintr/components/SpintrScrollChecker";
import { ResponsiveFooter } from "./spintr/components/ResponsiveFooter";
import SystemStatusesPrioView from "./system-status/SystemStatusesPrioView";

// Code-Splitting
const RealtimeLoader = lazy(() => import("src/realtime/RealtimeLoader"));
const EmergencyAlertView = lazy(() => import("./ui/components/EmergencyAlert/EmergencyAlertView"));
const UnreadEmailsLoader = lazy(() => import("./profile/components/UnreadEmailsLoader"));
const SpintrMainContentContainer = lazy(() => import("src/spintr/components/SpintrMainContentContainer"));

interface ISpintrProps {
    appsLoaded: boolean;
    currentUser: IActiveUserProfile;
    sidebarIsExpanded: boolean;
    isSmallViewMode: boolean;
    isTinyInModal: boolean;
    privileges: any;
    dispatch: any;
    location?: any;
    instance: any;
    instanceId: number;
    instanceColor: string;
    useColorHeader: boolean;
    instanceFaviconUrl: string;
    instanceLogoUrl: string;
    instanceName: string;
    appMode: boolean;
    visage2FullscreenMode: boolean;
    navigation?: any;
    introductionsEnabled: boolean;
    profileFetchHasBeenInitialized: boolean,
    appFetchHasBeenInitialized: boolean,
    instanceFetchHasBeenInitialized: boolean,
    privilegesFetchHasBeenInitialized: boolean,
    sidebarMode: VisageSidebarMode,
    startPageBuilderMode: SpintrTypes.StartPageBuilderMode,
    displayGreyBackground: boolean;
    isInTeamsApp: boolean;
    hasFormFooterBar: boolean;
    systemStatusEnabled: boolean;
}

type Props = ISpintrProps;

interface ISpintrState {
    langFetchDone: boolean;
    displayMfaWizard: boolean;
}

class Spintr extends Component<Props, ISpintrState> {
    private startupGuidesRef: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            langFetchDone: false,
            displayMfaWizard: false,
        };
    }

    setFavicon(url) {
        let faviconEl = document.getElementById("favicon");

        // @ts-ignore
        faviconEl.href = url;
    }

    setTitle(title) {
        document.title = title;
    }

    private async postUserSessionInit(): Promise<void> {
        try {
            await api.post("/api/statistics/usersessioninit");
        } catch (_) {
            // ignored
        }
    }

    private async postStartDeliverVisitedStartPage(): Promise<void> {
        try {
            await api.post("/api/v1/startdeliver/happenings");
            if (this.props.isInTeamsApp) {
                startDeliverTrack(SpintrTypes.InsightsType.VisitedTeamsApp)
            }
        } catch (_) {
            // ignored
        }
    }

    private initMixpanel = () => {
        initializeMixpanel(this.props.currentUser.email, this.props.currentUser.instanceId);
    }

    public componentDidMount() {
        if (this.props.isInTeamsApp) {
            setUserAgentExtension("Teams/1.0");
        }

        this.doInitialFetches();
        this.setTitle(this.props.instanceName);
        document.addEventListener("beforeunload", this.clearLocalStorage, false);
    }

    componentWillUnmount() {
        document.removeEventListener("beforeunload", this.clearLocalStorage, false);
    }

    clearLocalStorage() {
        localStorage.removeItem("spintr_external_files");
    }

    doInitialFetches() {
        if (!this.props.profileFetchHasBeenInitialized) {
            this.props.dispatch(getActiveUser()).then(() => {
                this.postUserSessionInit();
                this.postStartDeliverVisitedStartPage();
                this.initMixpanel();
            });
        }

        if (!this.props.appFetchHasBeenInitialized) {
            this.props.dispatch(fetchApps());
        }

        if (!this.props.instanceFetchHasBeenInitialized) {
            this.props.dispatch(getInstance()).then((thunkData) => {
                const responsePayload = thunkData?.value?.data;
                if (responsePayload?.aiAssistant?.enabled) {
                    // this.props.dispatch(setHeaderSearchBarMode(SpintrTypes.HeaderSearchBarMode.Assistant));
                }
                
                this.setFavicon(this.props.instanceFaviconUrl);
                this.setTitle(this.props.instanceName);

                document.documentElement.style.setProperty("--primaryColor", this.props.instanceColor);
            });
        }

        if (!this.props.privilegesFetchHasBeenInitialized) {
            this.props.dispatch(getPrivileges());
        }

        fetchLangTags(() => {
            this.setState({
                langFetchDone: true,
            });
        });

        this.props.dispatch(fetchImportantArticles());

        api.get("/api/v1/notifications/unread").then((response) => {
            this.props.dispatch(setUnreadCount(response.data));
        }).catch(() => { });

        api.get("/api/v1/conversations/unread?fetchType=2").then((response) => {
            this.props.dispatch(setUnreadCountPersonConversations(response.data));
        }).catch(() => { });

        api.get("/api/v1/conversations/unread?fetchType=3").then((response) => {
            this.props.dispatch(setUnreadCountGroupConversations(response.data));
        }).catch(() => { });
    }

    public render(): ReactNode {
        let reactPlugin = null;
        let appInsights = null;

        return (
            <div className={"Spintr"}>
                { /* @ts-ignore */}
                <TelemetryProvider connectionString="InstrumentationKey=a4bfe1bd-004b-406b-ad49-f7706e134742;IngestionEndpoint=https://westeurope-0.in.applicationinsights.azure.com/"
                    after={() => { appInsights = getAppInsights() }}>
                    <Helmet titleTemplate={`${this.props.instanceName} - %s`} defaultTitle={this.props.instanceName} />
                    <SpintrCustomStyleProvider>
                        <SpintrWidthWatcher />
                        <SpintrLocationChecker />
                        <SpintrNavigationBlocker />
                        <SpintrOverlay />
                        <Suspense fallback={
                            <div className="initialLoader">
                                <SpintrLoader />
                            </div>
                        }>
                            {this.renderApp()}
                        </Suspense>
                    </SpintrCustomStyleProvider>
                </TelemetryProvider>

            </div>
        );
    }

    private getAllIntroductionsForPath = (path) => {
        const allIntroductionsForPath = this.props.currentUser.introductionSequences?.filter(i => i.path === path);

        return allIntroductionsForPath;
    }

    public renderApp(): ReactNode {
        if (
            !this.props.instanceId ||
            !this.props.currentUser.id ||
            !this.props.appsLoaded ||
            !this.state.langFetchDone ||
            !this.props.privileges.isLoaded
        ) {
            return (
                <div className="initialLoader">
                    <SpintrLoader />
                </div>
            );
        }

        const visageContentClassNames = ["VisageContent"];
        if (!this.props.sidebarIsExpanded) {
            visageContentClassNames.push("extra-width");
        }

        const mainWrapClassNames = ["mainWrap"];
        if (!this.props.sidebarIsExpanded) {
            mainWrapClassNames.push("extra-width");
        }

        if (this.props.isSmallViewMode) {
            mainWrapClassNames.push("isSmallViewMode");
            document.body.classList.add("isSmallViewMode");
        } else {
            document.body.classList.remove("isSmallViewMode");
        }

        if (this.props.isTinyInModal) {
            document.body.classList.add("tiny-modal");
        } else {
            document.body.classList.remove("tiny-modal");
        }

        let appWrapClassList = ["appWrap"];

        let displayVisageSidebar = !this.props.isSmallViewMode && !this.props.appMode;
        if (this.props.currentUser.roles.indexOf("supplier") !== -1) {
            displayVisageSidebar = false;
        }

        if (!displayVisageSidebar) {
            appWrapClassList.push("VisageSideBarHidden");
        }

        // if (window.location.pathname.indexOf("/admin") === 0 ||
        //     // window.location.pathname.indexOf("/products") === 0 ||
        //     window.location.pathname.indexOf("/marketplace") === 0) {
        //     appWrapClassList.push("whiteBackground");
        // }

        if (this.props.hasFormFooterBar) {
            appWrapClassList.push("hasFormFooterBar");
        }

        const pathHasIntroductions = (this.getAllIntroductionsForPath(window.location.pathname)?.length ?? 0) > 0;

        const app = (
            <div className={appWrapClassList.join(" ")}>
                {!this.props.isSmallViewMode && !this.props.appMode && !this.props.visage2FullscreenMode && (
                    <SpintrHeader
                        logoTitle={this.props.instanceName}
                        logoUrl={this.props.instanceLogoUrl}
                        runIntroduction={
                            !this.props.isSmallViewMode && this.props.introductionsEnabled && pathHasIntroductions
                                ? () => {
                                      if (this.startupGuidesRef && this.startupGuidesRef.runIntroJs) {
                                          this.startupGuidesRef.runIntroJs(window.location, 0, true);
                                      }
                                  }
                                : undefined
                        }
                    />
                )}
                <div className="appInner">
                    {displayVisageSidebar && (
                        <VisageSidebar />
                    )}
                    <div className={visageContentClassNames.join(" ")}>
                        <RealtimeLoader />
                        <ReactionPicker />
                        <MobileAppBridge />
                        <UnreadEmailsLoader />
                        {!this.props.isSmallViewMode && !this.props.appMode && (
                            <>
                                <ChatTabContainer />
                            </>
                        )}
                        {this.props.isSmallViewMode && !this.props.appMode && (
                            <>
                                <ResponsiveDataLoader />
                                <ResponsiveMenu />
                                <ResponsiveProfile />
                                <ResponsiveSearch />
                                <ResponsiveShortcuts />
                            </>
                        )}
                        <div className={mainWrapClassNames.join(" ")}>
                            {this.props.isSmallViewMode && !this.props.appMode && (
                                <ResponsiveHeader
                                    logoTitle={this.props.instanceName}
                                    logoUrl={this.props.instanceLogoUrl}
                                />
                            )}
                            <div
                                className={classNames("contentWrap", {
                                    grayBackground:
                                        window.location.pathname.includes("/edit-startpage") &&
                                        this.props.startPageBuilderMode === SpintrTypes.StartPageBuilderMode.Edit
                                })}
                            >
                                {
                                    this.props.instance.get("backgroundType") === 2 && (
                                        <VisageGradient />
                                    )
                                }
                                {this.props.currentUser.roles.indexOf("supplier") === -1 && (
                                    <VisageResponsiveSubmenu />
                                )}
                                <OfflineBanner />
                                <UpdateReloadBanner />
                                <AppFeedbackBanner />
                                {this.props.systemStatusEnabled && !this.props.location.pathname.startsWith("/admin") && <SystemStatusesPrioView />}
                                {!this.props.appMode && (
                                    <EmergencyAlertView />
                                )}
                                <SpintrMainContentContainer />
                                <NotificationCard />
                                <SpintrScrollChecker />
                                {this.state.displayMfaWizard && (
                                    <MfaWizard show={true} enforced={true} onClose={this.onDismissMfaWizard} />
                                )}
                                {this.props.currentUser.roles.indexOf("supplier") === -1 && (
                                    <StartupGuides
                                        //@ts-ignore
                                        onRef={(ref) => (this.startupGuidesRef = ref)} />
                                )}
                            </div>
                            {this.props.isSmallViewMode && !this.props.appMode && (
                                <ResponsiveFooter />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );

        return <RealtimeContextProvider>{app}</RealtimeContextProvider>;
    }

    private onDismissMfaWizard = () => {
        this.setState({ displayMfaWizard: false });

        this.props.dispatch(updateActiveUser({
            ...this.props.currentUser,
            mfaEnabled: true,
        }));
    };

    public componentDidUpdate(prevProps: Readonly<ISpintrProps>, prevState: Readonly<ISpintrState>, snapshot?: any): void {
        if (prevProps.location.hash !== this.props.location.hash) {
            const hash = (this.props.location.hash || "");
            if (hash.length > 1) {
                // This doesn't feel like idiomatic React code, but I'm not sure how else to do it
                const element = window.document.querySelector<HTMLElement>(hash);
                if (element) {
                    setTimeout(() => {
                        element.scrollIntoView({ behavior: "smooth" });
                    }, 500);
                }    
            }
        }

        this.setState({
            displayMfaWizard: this.props.instance.get("enforceMfa") &&
                !this.props.currentUser.mfaEnabled &&
                !this.props.currentUser.isSpintrAdminAccount
        });
    }

    public shouldComponentUpdate(nextProps: Props, nextState: ISpintrState): boolean {      
        if (
            nextProps.location.hash !== this.props.location.hash ||
            nextProps.location.pathname !== this.props.location.pathname ||
            nextState.langFetchDone !== this.state.langFetchDone ||
            // nextState.openBlockNavigationModal !== this.state.openBlockNavigationModal ||
            nextProps.instance.get("instanceId") !== this.props.instance.get("instanceId") ||
            nextProps.instance.get("color") !== this.props.instance.get("color") ||
            nextProps.instance.get("useColorHeader") !== this.props.instance.get("useColorHeader") ||
            nextProps.instance.get("headerColor") !== this.props.instance.get("headerColor") ||
            nextProps.instance.get("searchFieldBackgroundColor") !== this.props.instance.get("searchFieldBackgroundColor") ||
            nextProps.instance.get("accentColor") !== this.props.instance.get("accentColor") ||
            nextProps.instance.get("logoUrl") !== this.props.instance.get("logoUrl") ||
            nextProps.instance.get("fontUrl") !== this.props.instance.get("fontUrl") ||
            nextProps.instance.get("bodyFontUrl") !== this.props.instance.get("bodyFontUrl") ||
            nextProps.instance.get("menuFontUrl") !== this.props.instance.get("menuFontUrl") ||
            nextProps.instance.get("useUppercaseMainMenu") !== this.props.instance.get("useUppercaseMainMenu") ||
            nextProps.instance.get("backgroundType") !== this.props.instance.get("backgroundType") ||
            nextProps.instance.get("enforceMfa") !== this.props.instance.get("enforceMfa") ||
            nextProps.currentUser.mfaEnabled !== this.props.currentUser.mfaEnabled ||
            nextProps.currentUser.id !== this.props.currentUser.id ||
            nextProps.appsLoaded !== this.props.appsLoaded ||
            nextProps.sidebarIsExpanded !== this.props.sidebarIsExpanded ||
            nextProps.isSmallViewMode !== this.props.isSmallViewMode ||
            // nextProps.isSmallerThanSmallestLaptopViewMode !== this.props.isSmallerThanSmallestLaptopViewMode ||
            nextProps.isTinyInModal !== this.props.isTinyInModal ||
            nextProps.privileges.isLoaded !== this.props.privileges.isLoaded ||
            nextProps.appMode !== this.props.appMode ||
            nextProps.visage2FullscreenMode !== this.props.visage2FullscreenMode ||
            nextProps.displayGreyBackground !== this.props.displayGreyBackground ||
            nextProps.sidebarMode !== this.props.sidebarMode ||
            nextProps.startPageBuilderMode !== this.props.startPageBuilderMode ||
            nextState.displayMfaWizard !== this.state.displayMfaWizard ||
            nextProps.hasFormFooterBar !== this.props.hasFormFooterBar
        ) {
            return true;
        }

        return false;
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    appsLoaded: state.app.isLoaded,
    currentUser: state.profile.active,
    instance: state.instance,
    instanceId: state.instance.get("instanceId"),
    instanceColor: state.instance.get("color"),
    useColorHeader: state.instance.get("useColorHeader"),
    instanceFaviconUrl: state.instance.get("faviconUrl"),
    instanceLogoUrl: state.instance.get("logoUrl"),
    instanceName: state.instance.get("name"),
    isSmallViewMode: state.ui.isSmallViewMode,
    isTinyInModal: state.ui.isTinyInModal,
    privileges: state.privileges,
    sidebarIsExpanded: state.ui.sidebarExpanded,
    appMode: state.ui.appMode,
    visage2FullscreenMode: state.ui.visage2FullscreenMode,
    introductionsEnabled: state.instance.get("enableIntroductions"),
    profileFetchHasBeenInitialized: state.profile.fetchHasBeenInitialized,
    appFetchHasBeenInitialized: state.app.fetchHasBeenInitialized,
    instanceFetchHasBeenInitialized: state.instance.get("fetchHasBeenInitialized"),
    privilegesFetchHasBeenInitialized: state.privileges.fetchHasBeenInitialized,
    sidebarMode: state.sidebar.mode,
    displayGreyBackground: state.ui.displayGreyBackground,
    startPageBuilderMode: state.ui.startPageBuilderMode,
    isInTeamsApp: state.ui.isInTeamsApp,
    hasFormFooterBar: state.ui.formFooterBarVisible,
    systemStatusEnabled: state.app.items.some(
        (app) => app.enabled && app.id === SpintrTypes.SpintrApp.SystemStatus,
    ),
});

export default withRouter(connect(mapStateToProps)(Spintr));
