
import * as microsoftTeams from "@microsoft/teams-js";
import { List, Map } from 'immutable';
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { IApplicationState } from 'src/spintr/index';
import { Label, PrioItems } from "src/ui";
import CenteredPageHeader from 'src/wikis/views/CenteredPageHeader';
import "./MenuPage.scss";

interface IProps {
    instance?: any;
    menuItems?: Spintr.IMainMenuItem[];
    dispatch?: any;
    isInTeamsApp?: boolean;
    history?: any;
    apps?: any;
}

interface IState {
}

class MenuPage extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderItemText(title) {
        return (
            <Label
                as="span"
                size="h6">
                {title}
            </Label>
        )
    }

    getValidApps() {
        let apps = [
            {id: 3, name: localize("appAbsence"), url: "/absence", icon: "calendar"},
            {id: 27, name: localize("appActivityLog"), url: "/activitylog", icon: "activity"},
            {id: 2, name: localize("appBlogs"), url: "/blogs", icon: "card-edit"},
            {id: 10, name: localize("appBookings"), url: "/booking", icon: "car", moduleKey: "bookings"},
            {id: 6, name: localize("appCalendars"), url: "/calendar", icon: "calendar"},
            {id: 7, name: localize("appFiles"), url: "/files", icon: "folder"},
            {id: 1, name: localize("appMedia"), url: "/media", icon: "gallery"},
            {id: 5, name: localize("appNews"), url: "/news", icon: "firstline"},
            {id: 25, name: localize("appNotes"), url: "/notes", icon: "document"},
            {id: 23, name: localize("appOperatingInfo"), url: "/operatinginfo", icon: "info-circle"},
            {id: 8, name: localize("appOrganisations"), url: "/organisation", icon: "hierarchy", moduleKey: "departmenttop"},
            {id: 26, name: localize("appQA"), url: "/qa", icon: "message-question"},
            {id: 28, name: localize("appSuppliers"), url: "/suppliers", icon: "hierarchy-square"},
            {id: 17, name: localize("appTodos"), url: "/todo", icon: "checkbox_checked"},
            {id: 9, name: localize("appWikis"), url: "/wikis", icon: "book"},
            {id: 0, name: localize("Grupper"), url: "/groups", icon: "profile-2user"},
        ];

        apps = apps.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        return apps;
    }

    handleItemClick(url) {
        const isExternal = url.indexOf("http") === 0;

        if (isExternal) {
            return window.open(url, "_blank");
        }

        if (this.props.isInTeamsApp) {
            const teamsAppId = this.props.instance.get("teamsAppId");

            if (!teamsAppId) {
                this.props.history.push({
                    pathname: url,
                });

                return;
            }

            const entityId = ((url.indexOf("nyheter") > -1) || (url.indexOf("news") > -1)) ?
                "news" :
                "index";

            const context = {
                subEntityId: url
            }

            const encodedContext = encodeURIComponent(JSON.stringify(context));

            const fullUrl = window.location.origin +
                (url.indexOf("/") === 0 ? "" : "/") +
                url;

            const encodedUrl = encodeURIComponent(fullUrl);

            const deeplinkUrl = "https://teams.microsoft.com/l/entity/" +
                teamsAppId +
                "/" +
                entityId +
                "?webUrl=" +
                encodedUrl +
                "&context=" +
                encodedContext;

            microsoftTeams.initialize(() => {
                microsoftTeams.executeDeepLink(deeplinkUrl, (status, reason) => {
                    console.log(status);
                    console.log(reason);
                    if (!status) {
                        window.open(fullUrl);
                    }
                });
            });
        } else {
            this.props.history.push({
                pathname: url,
            });
        }
    }

    getApps() {
        let result = [];
        let allApps = [...this.props.apps.items];
        let validApps = this.getValidApps();

        for (let app of allApps) {
            if (!app.enabled) {
                continue;
            }

            let appInfo = validApps.find(a => a.id === app.id);

            if (!appInfo) {
                continue;
            }

            result.push({
                id: appInfo.id,
                name: appInfo.name,
                url: appInfo.url,
                imageUrl: "",
                icon: appInfo.icon,
                description: "",
                onClick: () => {
                    this.handleItemClick(appInfo.url);
                }
            });
        }

        return result.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    getMenu() {
        let result = [];
        let validApps = this.getValidApps();

        this.props.menuItems.map((item, index) => {
            let matchingApp = validApps.find(a => a.url === item.url || (a.moduleKey && a.moduleKey == item.moduleKey));

            result.push({
                id: index,
                url: "",
                name: item.title,
                imageUrl: item.imageUrl,
                icon: !!matchingApp ? matchingApp.icon : item.icon,
                description: "",
                onClick: () => {
                    let url = item.url;

                    this.handleItemClick(url);
                }
            })
        });

        return result;
    }

    render() {
        return (
            <div className="MenuPage">
                <div>
                    <CenteredPageHeader
                        title={localize("MENU_PAGE_STRUCTURE")}
                    />
                    <div>
                        <PrioItems items={this.getMenu()} />
                    </div>
                </div>
                <div>
                    <CenteredPageHeader
                        title={localize("Funktioner")}
                    />
                    <div>
                        <PrioItems items={this.getApps()} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props : IProps) => ({
    ...props,
    instance: state.instance,
    menuItems: state.menu.main.items,
    isInTeamsApp: state.ui.isInTeamsApp,
    apps: state.app
});

const ConnectedMenuPage = connect(mapStateToProps)(MenuPage);

export default withRouter(ConnectedMenuPage);
