import { Image } from "@fluentui/react";
import axios from "axios";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import api from "src/spintr/SpintrApi";
import { Label, Loader } from "src/ui";
import { AppPageContext } from "../AppPageContext";
import { localizeFromDict } from "src/l10n/localize";

interface IProps extends IAppPageComponent { }

interface IState {
    isLoading: boolean;
    items: any[];
}

const AppPageList: FunctionComponent<IProps> = (props) => {
    const context = useContext(AppPageContext);

    const [state, setState] = useState<IState>({
        isLoading: false,
        items: [],
    });

    useEffect(
        () => {
            setState((s) => ({
                ...s,
                isLoading: true,
            }));

            const cancelTokenSource = axios.CancelToken.source();

            const promise = api.post(
                `/api/v1/MarketplaceApplicationPage/${context.appId}/request`, {
                    requestName: props.requestName
                }, {
                    cancelToken: cancelTokenSource.token,
                }
            );

            promise.then(
                (response) => setState({
                    isLoading: false,
                    items: response.data,
                }),
                (reason) => setState((s) => ({
                    ...s,
                    isLoading: false,
                }))
            );

            return () => cancelTokenSource.cancel();
        },
        [setState, context.appId, props.requestName],
    );
    
    return (
        <div className="AppPageList">
            <div className="header">
                <Label
                    as="div"
                    className="header-text"
                    color="black"
                    size="h5"
                >
                {localizeFromDict(context.text, props.text)}
                </Label>
            </div>
            <ol className="list">
                {state.isLoading && <Loader />}
                {!state.isLoading && state.items.slice(0, 3).map((item, index) => (
                    <li
                        className="list-item"
                        key={item.key || index.toString()}
                    >
                        {item.imageUrl && (
                            <Image
                                alt={item.text}
                                className="item-image"
                                height={32}
                                src={item.imageUrl}
                                width={32}
                            />
                        )}
                        <Label
                            as="div"
                            className="text"
                            color="dark-grey"
                            size="body-2"
                            weight="medium"
                        >
                            {item.text}
                        </Label>
                        {item.value && (
                            <Label
                                as="div"
                                className="value"
                                color="mid-grey"
                                size="body-2"
                                weight="normal"
                            >
                                {item.value}
                            </Label>
                        )}
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default AppPageList;
