import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { localize } from 'src/l10n';
import { SpintrTypes } from 'src/typings';
import { Label } from 'src/ui';
import "./AppDisabledBanner.scss";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { Link } from "react-router-dom";

export type StateProps = {
    isAdmin: boolean;
    learningPublished: boolean;
    plannerPublished: boolean;
    operationsPublished: boolean;
    appMode: boolean;
    academyAppName: string;
};

interface AppDisabledBannerProps {
    type: string;
}

const AppDisabledBanner = (props: AppDisabledBannerProps) => {
    const [showBanner, setShowBanner] = useState<boolean>(false);

    const { isAdmin, learningPublished, plannerPublished, operationsPublished, appMode, academyAppName } = useSelector<Spintr.AppState, StateProps>(
        (appState) => ({
            isAdmin: appState.profile.active.roles.includes("administrators"),
            learningPublished: appState.app.items.some((app) => app.published && app.id === SpintrTypes.SpintrApp.Academy),
            plannerPublished: appState.app.items.some((app) => app.published && app.id === SpintrTypes.SpintrApp.Planner),
            operationsPublished: appState.app.items.some((app) => app.published && app.id === SpintrTypes.SpintrApp.SystemStatus),
            appMode: appState.ui.appMode,
            academyAppName: appState.instance.get("academyAppName"),
        })
    );

    const header = academyAppName ? localize(props.type) : academyAppName;

    useEffect(() => {
        let shouldShow = false;

        if (props.type === localize(academyAppName, true) && !learningPublished) {
            shouldShow = true;
        } else if (props.type === localize("appPlanner") && !plannerPublished) {
            shouldShow = true;
        } else if (props.type === localize("appSystemStatus") && !operationsPublished) {
            shouldShow = true;
        }

        if (isAdmin && shouldShow) {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    }, [isAdmin, learningPublished, plannerPublished, operationsPublished, props.type]);

    const linkToSettings = !appMode ? (
        <Link className={"linkFGColor"} to={`admin/topmenu`}>
            {localize("APP_DISABLED_BANNER_LINK")}
        </Link>
    ) : (
        localize("APP_DISABLED_BANNER_LINK")
    );

    if (!isAdmin || !showBanner) {
        return null;
    }

    const appDisabledBannerInfo = localize("APP_DISABLED_BANNER_INFO").replace("{0}", header);
    const messageParts = appDisabledBannerInfo.split(/\{1\}/gm);

    return (
        <div className="AppDisabledBanner">
            <div className="AppDisabledBanner-inner">
                <div className="AppDisabledBanner-info-circle">
                    <Visage2Icon icon="info-circle" />
                </div>
                <Label size="body-1" color="black" weight="normal">
                    {messageParts[0]}
                    {linkToSettings}
                    {messageParts[1]}
                </Label>
            </div>
        </div>
    );
};

export default AppDisabledBanner;
