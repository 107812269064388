import axios, { AxiosResponse } from 'axios';
import api from "src/spintr/SpintrApi";
import { IAcademyChapter, IAcademyCourse, IAcademyQuestion, IAcademyTest, ICourseStep } from "./types";

const isChapterDone = (chapter: IAcademyChapter) : boolean => {
    return chapter.lessons.every(x => x.userProgress.isCompleted) &&
        (!chapter.enableTest || chapter.test.userProgress.isCompleted);
}

const getSteps = (course: IAcademyCourse): ICourseStep[] => {
    const steps: ICourseStep[] = [];

    for (let chapter of course.chapters) {
        for (let lesson of chapter.lessons) {
            steps.push({
                id: lesson.id,
                isCompleted: lesson.userProgress.isCompleted,
                isEligable: lesson.userProgress.isEligable,
                type: "lesson"
            });
        }

        if (chapter.enableTest) {
            steps.push({
                id: chapter.test.id,
                isCompleted: chapter.test.userProgress.isCompleted,
                isEligable: chapter.test.userProgress.isEligable,
                type: "test"
            });
        }
    }

    if (course.enableFinalExam) {
        steps.push({
            id: course.test.id,
            isCompleted: course.test.userProgress.isCompleted,
            isEligable: course.test.userProgress.isEligable,
            type: "test"
        });
    }
    
    return steps;
}

const isEligableToStep = (steps: ICourseStep[], id: number): boolean => {
    for (let step of steps) {
        if (step.id === id) {
            return true;
        } else if (!step.isCompleted) {
            return false;
        }
    }

    return false;
}

const getChapterProgressPercent = (chapter: IAcademyChapter) : number => {
    const steps = [
        true,
        ...(chapter.lessons.map(x => x.userProgress.isCompleted)),
        ...(chapter.enableTest ? [chapter.test.userProgress.isCompleted] : [])
    ];

    return (steps.filter(x => x).length / steps.length) * 100;
}

const getCourseProgressPercent = (course: IAcademyCourse) : number => {
    let progress = [];

    course.chapters.forEach(c => {
        c.lessons.forEach(l => {
            progress.push(l.userProgress.isCompleted);
        });

        if (c.enableTest) {
            progress.push(c.test.userProgress.isCompleted);
        }
    });

    if (course.enableFinalExam) {
        progress.push(course.test.userProgress.isCompleted);
    }

    return progress.length > 0 ? course.userProgress.completionPercent = Math.round(
        (progress.filter(x => x).length / progress.length) * 100
    ) : 0;
}

export const markStepAsCompleted = (course: IAcademyCourse, id: number) : IAcademyCourse => {
    for (let chapter of course.chapters) {
        for (let lesson of chapter.lessons) {
            if (lesson.id === id) {
                lesson.userProgress.isCompleted = true;
                chapter.userProgress.isCompleted = isChapterDone(chapter);
            }
        }

        if (chapter.enableTest && chapter.test.id === id) {
            chapter.test.userProgress.isCompleted = true;
            chapter.userProgress.isCompleted = true;
        }
    }

    if (course.enableFinalExam && course.test.id === id) {
        course.test.userProgress.isCompleted = true;
        course.userProgress.isCompleted = true;
    }

    return course;
}

export const getPreviousStep = (course: IAcademyCourse, currentStepId: number): ICourseStep => {
    const steps = getSteps(course);

    for (let i = 0; i < steps.length; i++) {
        if (i !== 0 && steps[i].id === currentStepId) {
            return steps[i - 1];
        }
    }

    return undefined;
}

export const getNextStep = (course: IAcademyCourse, previousStepId: number) : ICourseStep => {
    const steps = getSteps(course);

    for (let i = 0; i < steps.length; i++) {
        if (i !== 0 && steps[i - 1].id === previousStepId) {
            return steps[i];
        }
    }

    return undefined;
}

export const getActiveStep = (course: IAcademyCourse) : ICourseStep => {
    const steps = getSteps(course);

    for (let step of steps) {
        if (!step.isCompleted &&
            step.isEligable) {
            return step;
        }
    }

    return getSteps(course)[0];
}

export const updateCourseEligibality = (course: IAcademyCourse, firstTime?: boolean) : IAcademyCourse => {
    console.log("updateCourse", course, firstTime);
    if (course.isGuide && firstTime) {
        for (let chapter of course.chapters) {
            for (let lesson of chapter.lessons) {
                lesson.userProgress.isEligable = true;
                lesson.userProgress.isCompleted = false;
            }

            chapter.userProgress = {
                isCompleted: false,
                isEligable: true,
                completionPercent: 0,
            };
        }

        return course;
    }

    const steps = getSteps(course);

    for (let chapter of course.chapters) {
        for (let lesson of chapter.lessons) {
            lesson.userProgress.isEligable = course.isGuide ? true : isEligableToStep(steps, lesson.id);
        }

        if (chapter.enableTest) {
            chapter.test.userProgress.isEligable = isEligableToStep(steps, chapter.test.id);
        }
        
        chapter.userProgress = {
            isCompleted: isChapterDone(chapter),
            isEligable: chapter.lessons.length > 0 && chapter.lessons[0].userProgress.isEligable,
            completionPercent: getChapterProgressPercent(chapter)
        }
    }

    if (course.enableFinalExam) {
        course.test.userProgress.isEligable = isEligableToStep(steps, course.test.id);
    }

    course.userProgress.isCompleted = steps.every(x => x.isCompleted);
    course.userProgress.completionPercent = getCourseProgressPercent(course);

    return course;
}

export const saveTestAnswer = (test: IAcademyTest) : Promise<IAcademyTest> => {
    return new Promise((resolve, reject) => {
        api.post("/api/v1/academy/tests", test).then((response: AxiosResponse) => {
            const validationRequests = [];

            for (const question of response.data.questions.filter((x: IAcademyQuestion) => !x.answer.isValidated)) {
                validationRequests.push(api.post("/api/v1/academy/tests/" + test.id + "/answers/" + question.answer.id + "/validate"));
            }

            axios.all(validationRequests).then(() => {
                api.post("/api/v1/academy/tests/" + test.id + "/verify").then((verifyResult: AxiosResponse) => {
                    resolve(verifyResult.data);
                }).catch(reject);
            }).catch(reject);
        }).catch(reject);
    });
}

export const isFirstStep = (course: IAcademyCourse, id: number) => {
    const steps = getSteps(course);

    return steps[0].id === id;
}

export const isLastStep = (course: IAcademyCourse, id: number) => {
    const steps = getSteps(course);

    return steps[steps.length - 1].id === id;
}