import React from "react";
import { IAcademyChapter, IAcademyCourse, IAcademyLesson, IAcademyTest } from "src/academy/types";
import "./AcademyCourseSidebarContent.scss";
import AcademyCourseSidebarChapter from "../AcademyCourseSidebarChapter/AcademyCourseSidebarChapter";
import AcademyCourseSidebarFinalExam from "../AcademyCourseSidebarFinalExam/AcademyCourseSidebarFinalExam";
import AcademyCourseProgress from "../../AcademyCourseProgress/AcademyCourseProgress";
import { Label, SpintrUser } from "src/ui";

interface IProps {
    course: IAcademyCourse;
    activeStepId?: number;
    goToLesson: (lesson: IAcademyLesson) => void;
    goToTest: (test: IAcademyTest) => void;
    goToFinalExam: () => void;
}

const AcademyCourseSidebarContent = (props: IProps) => {
    return (
        <div className="AcademyCourseSidebarContent">
            <div className="progress">
                <SpintrUser name={props.course.name} personalName={false} imageUrl={props.course.imageUrl} />
                {!props.course.isGuide && <AcademyCourseProgress userProgress={props.course.userProgress} />}
            </div>
            {props.course.chapters.map((c: IAcademyChapter) => {
                return (
                    <AcademyCourseSidebarChapter
                        key={c.id}
                        chapter={c}
                        activeStepId={props.activeStepId}
                        goToLesson={props.goToLesson}
                        goToTest={props.goToTest}
                        isGuide={props.course.isGuide} />
                )
            })}
            {props.course.enableFinalExam && (
                <AcademyCourseSidebarFinalExam
                    test={props.course.test}
                    activeStepId={props.activeStepId}
                    goToFinalExam={props.goToFinalExam} />
            )}
        </div>
    )
}

export default AcademyCourseSidebarContent;
